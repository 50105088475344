<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1"  />
                <!-- :items="items" -->
                <!-- /product list -->
                <div class="card" style="border: none !important;">
                    <div class="card-body pt-0">
                        <!-- <div class="table-top">
                            <div class="search-set">
                                <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div>
                                <div class="search-input">
                                    <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                            alt="img" /></a>
                                    <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                        <label>
                                            <input type="search" class="form-control form-control-sm"
                                                placeholder="Cari..." aria-controls="DataTables_Table_0"
                                                v-model="query" />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="wordset">
                                <ul>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="@/assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="@/assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="@/assets/img/icons/printer.svg" alt="img" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <!-- <div class="card mb-0" id="filter_inputs" :style="{
                            display: filter ? 'block' : 'none',
                        }">
                            <div class="card-body pb-0">
                                <div class="row">
                                    <div class="col-lg-12 col-sm-12">
                                        <div class="row">
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Product" placeholder="Choose Product" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Choose" placeholder="Choose Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Category" placeholder="Choose Sub Category" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Brand" placeholder="Brand" />
                                                </div>
                                            </div>
                                            <div class="col-lg col-sm-6 col-12">
                                                <div class="form-group">
                                                    <vue-select :options="Price" placeholder="Price" />
                                                </div>
                                            </div>
                                            <div class="col-lg-1 col-sm-6 col-12">
                                                <div class="form-group">
                                                    <a class="btn btn-filters ms-auto"><img
                                                            src="@/assets/img/icons/search-whites.svg" alt="img" /></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <!-- /Filter -->
                        <div class="table-responsive position-relative" >
                            <div class="feature-search position-absolute d-flex  align-items-center" v-if="isLoading == false" style="top: 15px; left: 0px; z-index: 1099;">
                                <!-- <div class="icon-container me-2">
                                    <i class='bx bx-import'></i>
                                     <img src="../../../assets/img/icon-table-header2.png" alt="import" style="width: 24px; height: 24px; ">
                                </div> -->
                                <div class="icon-container me-2" @click="convertToExcel">
                                    <!-- <i class='bx bx-export'></i> -->
                                    <img src="../../../assets/img/icon-table-header3.png" alt="export" style="width: 20px; height: 20px; ">

                                </div>
                                <!-- <div class="icon-container me-2">
                                    <i class='bx bxs-printer'></i>
                                </div> -->
                                <div class="icon-container ">
                                    <!-- <i class='bx bxs-cog'></i> -->
                                    <img src="../../../assets/img/icon-table-header1.png" alt="export" style="width: 20px; height: 20px; ">

                                </div>
                            </div>

                            <div class="search-set position-absolute  d-flex align-items-center me-2" v-if="isLoading == false" style="top: 15px; left: 80px; z-index: 1099;">
                                <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="@/assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="@/assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->
                                
                                <div class="search-input me-2">
                                        <a class="btn btn-searchset"><img src="@/assets/img/icons/search-white.svg"
                                                alt="img" /></a>
                                        <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm search-input"
                                                    placeholder="Cari..." aria-controls="DataTables_Table_0"
                                                    v-model="query" />
                                            </label>
                                        </div>
                                </div>
                                
                            </div>

                            <div class="add-set position-absolute " style="top: 15px; right: 30px; z-index: 1099;">
                                

                                <button type="button" @click="navigateTo('addproduct')" class="btn btn-primary rounded-circle p-0" style="width: 35px; height: 35px; font-size: 24px;">
                                    <i class='bx bx-plus'></i>
                                </button>
                            </div>

                            <a-table :columns="columns" :data-source="filteredData" @change="onChange" :loading="tableLoading" :pagination="{
                                position: ['top'],
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                showSizeChanger: false,
                            }" :class="{'custom-pt': isEmptyData}" :row-selection="{
                                    selectedRowKeys: selectedRowKeys,
                                    onChange: onSelectChange,
                                }" :row-key="record => record.id">
                                <template #bodyCell="{ column, record }">
                                    <!-- <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template> -->

                                    <template v-if="column.key === 'foto_produk'">
                                        <div class="productimgname">
                                            <a href="javascript:void(0);" class="product-img">
                                                <img :src="record.gambar ? record.gambar : require('@/assets/img/product/noimage.png')" alt="product" />
                                            </a>

                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->

                                            <!-- <a href="javascript:void(0);">{{ record.nama }}</a> -->
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'nama'">
                                        <div class="productimgname">
                                            <!-- <a href="javascript:void(0);" class="product-img">
                                                <img :src="record.gambar" alt="product" />
                                            </a> -->
                                            <a href="javascript:void(0);">{{ record.nama }}</a>
                                        </div>
                                    </template>

                                    <template v-if="column.key === 'code'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.code }}</a>
                                        </div>
                                    </template>

                                    <template v-if="column.key === 'nama_kategori'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.nama_kategori }}</a>
                                        </div>
                                    </template>

                                    <template v-if="column.key === 'general_price'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ rupiah(record.general_price) }}</a>
                                        </div>
                                    </template>

                                    <!-- <template v-if="column.key === 'unit'">
                                        <div class="productimgname">
                                            
                                            <a href="javascript:void(0);">{{ record.unit }}</a>
                                        </div>
                                    </template> -->

                                    <template v-else-if="column.key === 'action'">
                                        <!-- <a class="me-3" @click="show_izitoast">
                                            <img src="@/assets/img/icons/eye.svg" alt="img" />
                                        </a> -->
                                        <!-- <router-link class="me-3" to="editproduct" @click="edit(record);">
                                            <img src="@/assets/img/icons/edit.svg" alt="img" />
                                        </router-link>
                                        <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.id, record.nama); showModal('terminate');">
                                            <img src="@/assets/img/icons/delete.svg" alt="img" />
                                        </a> -->

                                        <div class="d-flex align-items-center ">
                                            <a class="action-set" href="javascript:void(0);" data-bs-toggle="dropdown" aria-expanded="true">
                                                <!-- <i class="fa fa-ellipsis-v" aria-hidden="true"></i> -->
                                                <i class='bx bx-dots-horizontal-rounded' aria-hidden="true" style="font-size: 24px; color: black !important;"></i>
                                            </a>
                                            <ul class="dropdown-menu p-2" style="z-index: 1200;">
                                                <li class="text-center" style="border-bottom: 1px solid #f4f4f4;">
                                                    <!-- <router-link class="me-3 dropdown-item" to="editsuplier" @click="edit(record);">
                                                        <img src="@/assets/img/icons/edit.svg" alt="img" /> Edit 
                                                    </router-link> -->
                                                    <router-link to="editproduct" class="dropdown-item d-flex justify-content-center" @click="edit(record);"><img
                                                        src="@/assets/img/new-icon/Edit_light.png" class="me-2" alt="img" />
                                                        Edit</router-link>
                                                </li>
                                                <li>
                                                    <a class="confirm-text dropdown-item d-flex justify-content-center" href="javascript:void(0);"
                                                        @click="terminate(record.id, record.fullname); showModal('terminate');">
                                                        <img src="@/assets/img/new-icon/Trash.png" alt="img" class="me-2" /> Hapus
                                                    </a>
                                                    
                                                </li>
                                                
                                                
                                            </ul>
                                            
                                        </div>

                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- /product list -->
            </div>
        </div>
    </div>
    <!-- <button type="button" class="btn btn-primary" @click="">
        Launch demo modal
    </button> -->

    <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalCenterTitle" style="z-index: 1200;">
        <!-- aria-hidden="true" -->
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
                    <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form @submit.prevent="delete2">
                    <div class="modal-body">
                        Apakah anda yakin ingin menghapus data ini?
                    </div>
                    <div class="modal-footer d-flex justify-content-end">
                        <button type="button" class="btn  btn-secondary"
                            @click="hideModal2('terminate')">cancel</button>
                        <button type="submit" class="btn  btn-danger">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, AlertBottom, rupiah, goPage } from "@/assets/js/function";
import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
    data() {
        return {
            filter: false,
            title: "Daftar Produk",
            title1: "atur produk",
            items: [
                {
                    text: "Tambah produk baru",
                    addname: "/addproduct",
                },
            ],

            //data
            data: [],
            columns: [
                // {
                //     title: "No",
                //     dataIndex: "no",
                //     key: "no",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.no.toLowerCase();
                //             b = b.no.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Foto Produk",
                    dataIndex: "foto_produk",
                    key: "foto_produk",
                    sorter: {
                        compare: (a, b) => {
                            a = a.foto_produk.toLowerCase();
                            b = b.foto_produk.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "SKU",
                    dataIndex: "code",
                    key: "code",
                    sorter: {
                        compare: (a, b) => {
                            a = a.code.toLowerCase();
                            b = b.code.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Nama",
                    dataIndex: "nama",
                    key: "nama",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama.toLowerCase();
                            b = b.nama.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                
                {
                    title: "Stock",
                    dataIndex: "stok",
                    key: "stok",
                    sorter: {
                        compare: (a, b) => {
                            a = a.stok.toLowerCase();
                            b = b.stok.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                // {
                //     title: "Gudang",
                //     dataIndex: "nama_gudang",
                //     key: "nama_gudang",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.nama_gudang.toLowerCase();
                //             b = b.nama_gudang.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Avarage",
                    dataIndex: "average",
                    key: "average",
                    sorter: {
                        compare: (a, b) => {
                            a = a.average.toLowerCase();
                            b = b.average.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Harga",
                    dataIndex: "general_price",
                    key: "general_price",
                    sorter: {
                        compare: (a, b) => {
                            a = a.general_price.toLowerCase();
                            b = b.general_price.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                // {
                //     title: "Harga",
                //     dataIndex: "rate",
                //     key: "rate",
                //     sorter: {
                //         compare: (a, b) => {
                //             a = a.rate.toLowerCase();
                //             b = b.rate.toLowerCase();
                //             return a > b ? -1 : b > a ? 1 : 0;
                //         },
                //     },
                // },
                {
                    title: "Satuan",
                    dataIndex: "satuan_berat",
                    key: "satuan_berat",
                    sorter: {
                        compare: (a, b) => {
                            a = a.satuan_berat.toLowerCase();
                            b = b.satuan_berat.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },



                {
                    title: "",
                    key: "action",
                    sorter: false,
                },
            ],


            tanggal_terminate: "",
            alasan_terminate: "",
            id_terminate: "",
            nama_terminate: "",

            //filter
            query: "",

            isLoading: true,

            tableLoading: true,

        };
    },
    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    },


    computed: {

        filteredData() {
            if (this.query === '') {
                return this.data; // Return all data immediately if no query
            }

            const lowerCaseQuery = this.query.toLowerCase(); // Cache lowercased query for reuse

            // Remove dots from the query to handle prices without dots
            const sanitizedQuery = lowerCaseQuery.replace(/\./g, '');

            let counter = 1; // Initialize counter for 'no'

            // Use a single loop to filter and reset 'no'
            const filteredAndReset = this.data.reduce((acc, item) => {
                const matchesQuery =
                    (item.nama?.toLowerCase().includes(lowerCaseQuery) || false) ||
                    (item.code?.toLowerCase().includes(lowerCaseQuery) || false) ||
                    (item.nama_category?.toLowerCase().includes(lowerCaseQuery) || false) ||
                    (item.unit?.toLowerCase().includes(lowerCaseQuery) || false) ||
                    (item.general_price?.toLowerCase().includes(lowerCaseQuery) || false) ||
                    (item.stok?.toLowerCase().includes(lowerCaseQuery) || false) ||
                    (item.rate?.toLowerCase().includes(lowerCaseQuery) || false) ||
                    // Check for numeric price match (sanitize both the query and price)
                    (item.general_price && item.general_price.replace(/\./g, '').includes(sanitizedQuery));

                if (matchesQuery) {
                    acc.push({
                        ...item,
                        no: counter++ // Assign new 'no' and increment counter
                    });
                }

                return acc;
            }, []);

            // Sort only the filtered data
            return filteredAndReset.sort((a, b) => a.no - b.no);
        },


        // filteredData() {
        //     // Filter data based on selected jenis_kehadiran
        //     if (this.query == '') {
        //         return this.data; // If no filter selected, return all data
        //     }
        //     const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison

        //     // Filter the data
        //     const filtered = this.data.filter(item =>
        //         item.nama.toLowerCase().includes(lowerCaseQuery) ||
        //         item.code.toLowerCase().includes(lowerCaseQuery) ||
        //         item.nama_kategori.toLowerCase().includes(lowerCaseQuery) ||
        //         item.unit.toLowerCase().includes(lowerCaseQuery) ||
        //         item.rate.toLowerCase().includes(lowerCaseQuery)
        //     );

        //     // Sort the filtered data by 'no' in ascending order
        //     const sorted = filtered.sort((a, b) => a.no - b.no);

        //     // Reset 'no' values to start from 1
        //     const resetNos = sorted.map((item, index) => {
        //         return {
        //             ...item,
        //             no: index + 1 // Set 'no' to index + 1
        //         };
        //     });

        //     return resetNos;
        // },

        isEmptyData() {
            return this.filteredData.length === 0;
        },
    },
    methods: {
        rupiah,
        show_izitoast() {
            AlertBottom('tessst');
        },
        navigateTo(route){
            goPage(route)
        },
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },

        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },

        addNumberToObjects(objArray) {
            for (let i = 0; i < objArray.length; i++) {
                const { nama, code, total_stok, average, general_price, unit, status_produk, 
                    note, modal_price, pricelist_price, 
                    enduser_price, retail_price, special_agent_price, supplier_price, ecommerce_price,  ...rest } = objArray[i];  // Extract fields to be renamed
                const newObj = {
                No: i + 1,
                "Nama Produk": nama,  // Rename id_customer to customer_id
                "SKU": code,
                "Stock": total_stok,
                "Average": average,
                "Harga": general_price,
                "Satuan": unit,
                "Status Produk": status_produk,
                // "Minimum Stok": minimum_stok,
                // "Maksimum Stok": maksimum_stok,
                "Harga Modal": modal_price,
                "Harga Pricelist": pricelist_price,
                "Harga Enduser": enduser_price,
                "Harga Retail": retail_price,
                "Harga Agen Khusus": special_agent_price,
                "Harga Supplier": supplier_price,
                "Harga Ecommerce": ecommerce_price,
                "Catatan": note,
                // "Panjang": panjang,
                // "Lebar": lebar,
                // "Tinggi": tinggi,
                

                
                // "Size": size,
                
                ...rest
                };
                objArray[i] = newObj;
            }
            return objArray;
        },
        convertToExcel() {
            ShowLoading();

            // Create a deep copy of `this.records` to avoid modifying the original data
            // const data = JSON.parse(JSON.stringify(this.record));
            const data = JSON.parse(JSON.stringify(this.filteredData));
            const ArrExport = this.addNumberToObjects(data);

            for (let i = 0; i < ArrExport.length; i++) {
                delete ArrExport[i]["id"];
                delete ArrExport[i]["category"];
                delete ArrExport[i]["status"];
                delete ArrExport[i]["id_profiles"];
                delete ArrExport[i]["id_users"];
                delete ArrExport[i]["id_group_product"];
                delete ArrExport[i]["id_merek"];
                delete ArrExport[i]["id_warehouse"];
                delete ArrExport[i]["id_kategori"];
                delete ArrExport[i]["created_at"];
                delete ArrExport[i]["updated_at"];
                delete ArrExport[i]["no"];
                delete ArrExport[i]["gambar"]; //matiin jika perlu gambar
                delete ArrExport[i]["minimal_order"];
                delete ArrExport[i]["name_group_product"];
                delete ArrExport[i]["ifnull(d.nama, '')"];
                delete ArrExport[i]["on_jual"];
                delete ArrExport[i]["on_katalog"];
                delete ArrExport[i]["on_bungkus"];
                delete ArrExport[i]["ismarketing"];
                delete ArrExport[i]["isdeposit"];
                delete ArrExport[i]["type_product"];
                delete ArrExport[i]["status_product"];
                delete ArrExport[i]["name"];
                delete ArrExport[i]["nama_kategori"];
                delete ArrExport[i]["unit2"];
                delete ArrExport[i]["unit2"];
                delete ArrExport[i]["rate"];
                delete ArrExport[i]["rate2"];
                delete ArrExport[i]["rate3"];
                delete ArrExport[i]["rate4"];
                delete ArrExport[i]["rate5"];
                delete ArrExport[i]["rate6"];
                delete ArrExport[i]["cost"];
                delete ArrExport[i]["stok"];
                delete ArrExport[i]["satuan_berat"];
                delete ArrExport[i]["panjang"];
                delete ArrExport[i]["lebar"];
                delete ArrExport[i]["tinggi"];
                delete ArrExport[i]["minimum_stok"];
                delete ArrExport[i]["maksimum_stok"];
                delete ArrExport[i]["deskripsi"];
                
            }

            // const footerRow = {
            //     No: '', // Leave the No column empty for the footer
            //     Supplier: '', // Empty for footer
            //     Kategori: '', // Empty for footer
            //     "Kode Produk": '', // Empty for footer
            //     Varian: '', // Empty for footer
            //     Size: 'TOTAL', // Empty for footer
            //     "Qty diterima": this.totalQty,// Total of qty_buy
            //     "Harga Modal": '', // Empty for footer
            //     Total: this.currencyInput(this.total, this.currency), // Total cost formatted as currency
                
            // };

            // ArrExport.push(footerRow);

            // Create a new workbook
            const wb = XLSX.utils.book_new();
            // Create a new worksheet
            const ws = XLSX.utils.json_to_sheet(ArrExport);
            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(wb, ws, 'REKAP');
            // Convert the workbook to an XLSX file and download it
            XLSX.writeFile(wb, 'Daftar Produk' + ".xlsx");
            CloseLoading();
        },

        // showAlert() {
        //     // Use sweetalert2
        //     Swal.fire({
        //         title: "Are you sure?",
        //         text: "You won't be able to revert this!",
        //         showCancelButton: true,
        //         confirmButtonColor: "#1BA0E2",
        //         cancelButtonColor: "#d33",
        //         confirmButtonText: "Yes, delete it!",
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             Swal.fire("Deleted!", "Your file has been deleted.");
        //         }
        //     });
        // },

        index() {
            // ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'items';
            } else {
                this.api = base_url + 'items';
            }
            axios
                .post(this.api, '', this.config)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.status == true) {
                        this.data = [];

                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });

                        this.isLoading = false

                        this.tableLoading = false;


                        //save kategori to local
                        localStorage.setItem('needData1', JSON.stringify(response.data.data_warehouse));
                        localStorage.setItem('needData2', JSON.stringify(response.data.data_merek));
                        localStorage.setItem('needData3', JSON.stringify(response.data.data_group_product));
                        localStorage.setItem('needData4', JSON.stringify(response.data.data_items_kategori));



                        // CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                        this.isLoading = false
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        edit(record) {
            localStorage.setItem('currentData', JSON.stringify(record));
        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("id", this.id_terminate);
            formData.append("nama", this.nama_terminate);


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'items_delete';
            } else {
                this.api = base_url + 'items_delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        terminate(id, nama) {
            this.id_terminate = id;
            this.nama_terminate = nama;
            this.tanggal_terminate = new Date();
        }
    },
    name: "productlist",
};
</script>

<style scoped>
.ant-tooltip {
    display: none !important;
}

.ant-pagination-total-text {
    margin-left: auto; 
    /* padding-right: 70px ; */
}

.ant-table-pagination.ant-pagination {
    padding-right: 100px ;

}

.custom-input {
    background-color: #F6F6F6;
    /* border: 1px solid #F6F6F6 !important; */
}

.feature-search {
    display: flex;
    align-items: center; /* Center aligns the icons vertically */
}

.icon-container {
    font-size: 24px; /* Adjust size as needed */
    margin-right: 10px; /* Space between icons */
    cursor: pointer; /* Changes the cursor to pointer on hover */
    border: none !important;
    padding: 5px; 
    border-radius: 4px; 
    display: inline-flex;
    align-items: center; /* Centers the content vertically */
    justify-content: center;

}

.icon-container i {
    color: #d6b884;
}

.search-set .search-input {
    background-color: #F6F6F6;
    border: none !important;
}

.custom-pt {
    padding-top: 4rem;
}

.search-input {
    border-color: #f4f4f4 !important;
    /* font-weight: 400 !important; */
    text-transform: none !important;
    /* color: #C3C3C3 !important; */
    border-radius: 5px !important;
    
}
</style>