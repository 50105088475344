<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar-menu></sidebar-menu>
    <div class="page-wrapper">
      
      <div class="content">
          <pageheader :title="title" :title1="title1" />

          <div class="menu-tab">
              <div class="d-flex justify-content-start align-items-center gap-4 order-2 order-md-1 mt-4 mt-md-0">
                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('umum');"
                      :class="{ 'menu-top': active_page === 'umum', 'text-muted': active_page !== 'umum' }" style="cursor: pointer;">
                      UMUM
                  </div>

                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('kontak');"
                      :class="{ 'menu-top': active_page === 'kontak', 'text-muted': active_page !== 'kontak' }" style="cursor: pointer;">
                      KONTAK
                  </div>
                  
                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('alamat');"
                      :class="{ 'menu-top': active_page === 'alamat', 'text-muted': active_page !== 'alamat' }" style="cursor: pointer;">
                      ALAMAT
                  </div>
  
                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('pembelian');"
                      :class="{ 'menu-top': active_page === 'pembelian', 'text-muted': active_page !== 'pembelian' }" style="cursor: pointer;">
                      PENJUALAN
                  </div>
  
                  <div class="font-weight-normal text-md position-relative  px-4 py-2" @click="activePage('pajak');"
                      :class="{ 'menu-top': active_page === 'pajak', 'text-muted': active_page !== 'pajak' }" style="cursor: pointer;">
                      PAJAK
                  </div>
                  
                  <div class="ms-auto mb-2" v-if="active_page != 'kontak'">
                      <!-- <button type="button" data-bs-toggle="modal" data-bs-target="#fakturModal" class="btn btn-primary"  style="background-color: transparent !important; border: 1px solid #D6B884; color: #D6B884;">Cari Faktur</button> -->
                      <div class="form-check d-flex align-items-center">
                          <input class="form-check-input me-2" type="checkbox" @change="toggleStatusAktif" v-model="isNonAktif" value="" id="flexCheckDefault" style="width: 28px; height: 28px;">
                          <label class="form-check-label" for="flexCheckDefault">
                              Non-Aktif
                          </label>
                      </div>
                  </div>
                  <div class="ms-auto mb-2" v-if="active_page === 'kontak'">
                      <button type="button" @click="addNewRow" class="btn btn-primary rounded-circle p-0" style="width: 35px; height: 35px; font-size: 24px;">
                          <i class='bx bx-plus'></i>
                      </button>
                      <!-- <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                          <label class="form-check-label" for="flexCheckDefault">
                              Non-Aktif
                          </label>
                      </div> -->
                  </div>
                  
                  
                  
              </div>

          </div>

          <!-- /add -->
          <form @submit.prevent="insert">
            <div class="card">
              <div class="card-body">
                  
                <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
                
                  <div class="row" :class="{ 'custom-active': active_page !== 'umum' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'umum'">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Info Umum</h5>
                          </div>
                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3 " >Nama Perusahaan <span class="text-danger">*</span></label>
                              <input type="text" v-model="currentData.nama_perusahaan" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3 " >No Telepon</label>
                              <input type="number" v-model="currentData.no_telepon" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3 " >Email</label>
                              <input type="text" v-model="currentData.email" class="form-control" placeholder="" >
                              
                            </div>

                            <!-- <div class="d-flex align-items-center mb-3">
                                <label for="" class="col-3 text-muted" style="font-weight: 300;">No. Telepon</label>
                                <div class="d-flex align-items-center gap-4">
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      Kena Pajak
                                    </label>
                                  </div>
                                  <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      Harga Termasuk Pajak
                                    </label>
                                  </div>
                                </div>
                                
                            </div> -->
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Ekspedisi</label>
                              <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Bidang Industri <span class="text-danger">*</span></label>
                              <model-select :options="needData1" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.jenis_industri" class="custom-width" placeholder=""></model-select>
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Metode Pembayaran <span class="text-danger">*</span></label>
                              <model-select :options="needData6" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.metode_pembayaran" class="custom-width" placeholder=""></model-select>
                              <!-- <input type="text" v-model="currentData.metode_pembayaran" class="form-control" placeholder="" > -->
                              
                            </div>  
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Website</label>
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.website" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >catatan</label>
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <textarea type="text" v-model="currentData.catatan_umum" class="form-control" placeholder="" ></textarea>
                              
                            </div>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Info Lainnya</h5>
                          </div>

                          <div class="" v-for="(item, index) in currentData.daftar_bank" :key="index">
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Alamat</label>
                              <textarea type="date" class="form-control" placeholder="" ></textarea>
                              
                            </div> -->
                            <div class="d-flex align-items-center mb-3" >
                              <label for="" class="col-3" >Nama Bank</label>
                              <input type="text" v-model="item.nama" class="form-control" placeholder="" >
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder="" /> -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Cabang</label>
                              <input type="text" v-model="item.cabang" class="form-control" placeholder="" >
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >No.Rekening</label>
                              <input type="number" v-model="item.nomor_rekening" class="form-control" placeholder="" >
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Atas Nama</label>
                              <input type="text" v-model="item.nama_account" class="form-control" placeholder="" >
                            </div>
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kelurahan</label>
                              <model-select :options="kelurahanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder="" disabled/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                          </div>

                        </div>
                      </div>
                      
                    </div>
                  </div>



                  <div class="row mb-2" :class="{ 'custom-active': active_page !== 'kontak' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'kontak'">
                      <table class="table table-responsive table-border">
                        <thead style="background-color: #D6B884;">
                          <tr>
                            <!-- <th scope="col">#</th> -->
                            <th scope="col " class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300;">Nama Lengkap</th>
                            <th scope="col " class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300;">Posisi Jabatan</th>
                            <th scope="col " class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300;">No.Whatsapp</th>
                            <th scope="col" class="border-1 border-end text-center text-white td-tabel" style="font-weight: 300; width: 20%;">Agama</th>
                            
                            <!-- <th scope="col" class="border-1 border-end text-center text-white">Tanggal Diminta</th> -->
                          </tr>
                        </thead>
                        <tbody style="border: 1px solid #fafafa;">
                          <tr v-for="(item, index) in currentData.daftar_kontak" :key="index" class="border border-1" style="height: 40px;">
                              <!-- <td class="text-center">{{ index + 1 }}</td> -->
                              <td class="text-center td-tabel"><input type="text" v-model="item.nama" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="text" v-model="item.posisi" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel"><input type="number" v-model="item.whatsapp" class="form-control" placeholder="" style="border: none !important;"></td>
                              <td class="text-center td-tabel">
                                <model-select :options="needData7" :settings="{ settingOption: value, settingOption: value }"
                                  v-model="item.agama" class="custom-width" placeholder="" ></model-select>
                                <!-- <input type="text" v-model="item.agama" class="form-control" placeholder="" style="border: none !important;"> -->
                              </td>
                              
                              <!-- <td class="text-center">{{ item.tanggal_diminta }}</td> -->
                              
                          </tr>
                          
                        </tbody>
                      </table>
                      
                    </div>
                  </div>

                  <!-- alamat penagihan & pengiriman -->
                  <div class="row " :class="{ 'custom-active': active_page !== 'alamat' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'alamat'">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Alamat Penagihan</h5>
                          </div>
                          <div class="">
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Nama Jalan <span class="text-danger">*</span></label>
                              <input type="text" v-model="currentData.alamat_penagihan" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Provinsi <span class="text-danger">*</span></label>
                              <!-- <model-select :options="provinsiList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.provinsi"  @change="handleProvinsi('alamat')" class="custom-width form-control" placeholder=""></model-select> -->
                              <model-select :options="provinsiList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.provinsi"  class="custom-width form-control" placeholder=""></model-select>
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Kota/Kabupaten <span class="text-danger">*</span></label>
                              <!-- <model-select :options="kabupatenList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kabupaten"  @change="handleKabupaten('alamat')" @click="handleProvinsi('alamat')"  class="custom-width me-1 form-control" placeholder=""></model-select> -->
                              <model-select :options="kabupatenList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kabupaten" class="custom-width me-1 form-control" placeholder=""></model-select>
                              
                              <input type="number" v-model="currentData.kode_pos" class="form-control" placeholder="Kode Pos (5 digit angka)" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Kecamatan <span class="text-danger">*</span></label>
                              <!-- <model-select :options="kecamatanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kecamatan" @change="handleKecamatan('alamat')" @click="handleKabupaten('alamat')" class="custom-width form-control" placeholder=""></model-select> -->
                              <model-select :options="kecamatanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kecamatan" class="custom-width form-control" placeholder=""></model-select>
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Kelurahan <span class="text-danger">*</span></label>
                              <!-- <model-select :options="kelurahanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kelurahan" @click="handleKecamatan('alamat')" class="custom-width form-control" placeholder=""></model-select> -->
                              <model-select :options="kelurahanList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kelurahan" class="custom-width form-control" placeholder=""></model-select>
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Catatan</label>
                              <textarea type="text" row="3" class="form-control" placeholder=""></textarea>
                              <!-- <input type="text" v-model="currentData.kode_pos" class="form-control" placeholder="" > -->
                              
                            </div>
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Alamat Lainnya</h5>
                          </div>
                          <div class="">
                            <div class="d-flex align-items-center mb-2 form-check">
                              <!-- <label for="" class="col-3" >Nama Jalan</label>
                              <input type="text" class="form-control" placeholder="" > -->
                              <div class="col-3"></div>
                              <input class="form-check-input me-2" @change="copyAddress" v-model="isSameAddress" type="checkbox" value="" id="flexCheckDefault">
                              <label class="form-check-label" for="flexCheckDefault">
                                Sama Dengan Alamat Penagihan
                              </label>
                              
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Nama Jalan</label>
                              <input type="text" v-model="currentData.alamat_lainnya" class="form-control" placeholder="">
                              
                            </div>

                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Provinsi</label>
                              <model-select :options="provinsiList2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.provinsi_lainnya" class="custom-width form-control" placeholder="" style="z-index:1004"></model-select>
                              <!-- <model-select :options="provinsiListLainnya" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.provinsi_lainnya" @change="handleProvinsi('alamat_lainnya')" class="custom-width form-control" placeholder="" style="z-index:1004"></model-select> -->
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Kota/Kabupaten</label>
                              <model-select :options="kabupatenList2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kabupaten_lainnya" class="custom-width me-1 form-control" placeholder=""></model-select>
                              <!-- <model-select :options="kabupatenListLainnya" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kabupaten_lainnya" @change="handleKabupaten('alamat_lainnya')" @click="handleProvinsi('alamat_lainnya')" class="custom-width me-1 form-control" placeholder=""></model-select> -->
                              <input type="number" v-model="currentData.kode_pos_lainnya" class="form-control" placeholder="Kode Pos (5 digit angka)" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Kecamatan</label>
                              <!-- <model-select :options="kecamatanListLainnya" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kecamatan_lainnya" @change="handleKecamatan('alamat_lainnya')" @click="handleKabupaten('alamat_lainnya')" class="custom-width form-control" placeholder=""></model-select> -->
                              <model-select :options="kecamatanList2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kecamatan_lainnya" class="custom-width form-control" placeholder=""></model-select>
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-2">
                              <label for="" class="col-3" >Kelurahan</label>
                              <model-select :options="kelurahanList2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kelurahan_lainnya" class="custom-width form-control" placeholder=""></model-select>
                              <!-- <model-select :options="kelurahanListLainnya" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kelurahan_lainnya"  @click="handleKecamatan('alamat_lainnya')" class="custom-width form-control" placeholder=""></model-select> -->
                              <!-- <input type="text" class="form-control" placeholder="" > -->
                              
                            </div>
                            
                            
                          </div>

                        </div>
                        
                      </div>
                      
                    </div>
                  </div>
                  <div class="row " :class="{ 'custom-active': active_page !== 'pembelian' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'pembelian'">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Penjualan</h5>
                          </div>
                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Termin <span class="text-danger">*</span></label>
                              <model-select :options="needData2" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.syarat_pembayaran" class="custom-width" placeholder=""></model-select>
                              <!-- <input type="date" class="form-control" placeholder="Nama Jalan" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Diskon</label>
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.diskon" class="form-control" placeholder="%" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Saldo Awal</label>
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.saldo_awal_hutang" @input="formatCurrency('saldo_awal_hutang', currentData.saldo_awal_hutang)" class="form-control" placeholder="Rp.0,-" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Per-Tanggal</label>
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="date" v-model="currentData.per_tanggal" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Catatan</label>
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <textarea type="text" v-model="currentData.catatan_pembelian" class="form-control" placeholder="" ></textarea>
                              
                            </div>
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kode Pos</label>
                              <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Info Lainnya</h5>
                          </div>
                          <div class="">
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Batas Maksimal <br> Hutang Jatuh Tempo <br> (Dalam Hari) <span class="text-danger">*</span></label>
                              <!-- <model-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="number" v-model="currentData.batas_hutang_jatuh_tempo" class="form-control" placeholder="15" :disabled="user_type !== 'OWNER'" @blur="validateDueDate" >

                              <!-- <input type="text" v-model="currentData.batas_hutang_jatuh_tempo" class="form-control" placeholder="Hari" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Mata Uang <span class="text-danger">*</span></label>
                              <model-select :options="needData3" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.mata_uang" class="custom-width" placeholder=""></model-select>
                              <!-- <input type="text" class="form-control" placeholder="%" > -->
                              
                            </div>
                            
                            
                          </div>

                        </div>
                      
                      </div>
                      
                    </div>
                  </div>

                  <div class="row " :class="{ 'custom-active': active_page !== 'pajak' }">
                    <div class="col-lg-12 mb-3" v-show="active_page == 'pajak'">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Pajak</h5>
                          </div>
                          <div class="">
                            
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Pajak</label>
                              <model-select :options="needData4" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.pajak" class="custom-width" placeholder=""></model-select>
                              <!-- <input type="date" class="form-control" placeholder="Nama Jalan" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >NPWP</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.npwp" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >No.PKP</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.no_pkp" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Tipe Pajak</label>
                              <model-select :options="needData5" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.tipe_pajak" class="custom-width" placeholder=""></model-select>
                              <!-- <input type="date" class="form-control" placeholder="" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Catatan</label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <textarea type="text" v-model="currentData.keterangan_pajak" class="form-control" placeholder="" ></textarea>
                              
                            </div>
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kode Pos</label>
                              <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                          </div>

                        </div>
                        <div class="col-md-6">
                          <div class="title-tab py-2 mb-3" style="border-bottom: 1px solid #aaabad">
                            <h5>Alamat</h5>
                          </div>
                          <div class="">
                            <div class="d-flex align-items-center mb-2 form-check">
                              <!-- <label for="" class="col-3" >Nama Jalan</label>
                              <input type="text" class="form-control" placeholder="" > -->
                              <div class="col-3"></div>
                              <input class="form-check-input me-2" @change="copyAddressPajak" v-model="isSameAddressPajak" type="checkbox" value="" id="flexCheckDefault">
                              <label class="form-check-label" for="flexCheckDefault">
                                Alamat Pajak Sama Dengan Alamat Penagihan
                              </label>
                              
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Alamat <span class="text-danger">*</span></label>
                              <!-- <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/> -->
                              <input type="text" v-model="currentData.alamat_pajak" class="form-control" placeholder="" >
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Provinsi <span class="text-danger">*</span></label>
                              <model-select :options="provinsiList3" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.provinsi_pajak"  class="custom-width" placeholder=""></model-select>
                              <!-- <model-select :options="provinsiListPajak" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.provinsi_pajak" @change="handleProvinsi('alamat_pajak')" class="custom-width" placeholder=""></model-select> -->
                              <!-- <input type="text" class="form-control" placeholder="%" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3" >
                              <label for="" class="col-3" >Kota/Kabupaten <span class="text-danger">*</span></label>
                              
                                <div class="d-flex align-items-center gap-3" >
                                    <model-select :options="kabupatenList3" :settings="{ settingOption: value, settingOption: value }"
                                        v-model="currentData.kabupaten_pajak" class="custom-select" placeholder="" style="width: 250px;"></model-select>
                                    <!-- <model-select :options="kabupatenListPajak" :settings="{ settingOption: value, settingOption: value }"
                                        v-model="currentData.kabupaten_pajak" @change="handleKabupaten('alamat_pajak')" @click="handleProvinsi('alamat_pajak')" class="custom-select" placeholder="" style="width: 250px;"></model-select> -->
                                    <input type="number" v-model="currentData.kode_pos_pajak" class="form-control" placeholder="Kode Pos (5 digit angka)" style="width: 105px;" >
                                </div>
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kecamatan <span class="text-danger">*</span></label>
                              <model-select :options="kecamatanList3" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kecamatan_pajak" class="custom-width" placeholder=""></model-select>
                              <!-- <model-select :options="kecamatanListPajak" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kecamatan_pajak" @change="handleKecamatan('alamat_pajak')" @click="handleKabupaten('alamat_pajak')" class="custom-width" placeholder=""></model-select> -->
                              <!-- <input type="text" class="form-control" placeholder="%" > -->
                              
                            </div>
                            <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kelurahan <span class="text-danger">*</span></label>
                              <model-select :options="kelurahanList3" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kelurahan_pajak" class="custom-width" placeholder=""></model-select>
                              <!-- <model-select :options="kelurahanListPajak" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.kelurahan_pajak" @click="handleKecamatan('alamat_pajak')" class="custom-width" placeholder=""></model-select> -->
                              <!-- <input type="text" class="form-control" placeholder="%" > -->
                              
                            </div>


                            
                            
                            
                            <!-- <div class="d-flex align-items-center mb-3">
                              <label for="" class="col-3" >Kode Pos</label>
                              <vue-select :options="statusList" :settings="{ settingOption: value, settingOption: value }"
                                v-model="currentData.status" class="custom-width" placeholder=""/>
                              <input type="text" class="form-control" placeholder="" >
                              
                            </div> -->
                          </div>

                        </div>
                      
                      </div>
                      
                    </div>
                  </div>

                  

                  
                
              </div>
            </div>
          
            <!-- /add -->

              <!-- <div class="p-3 d-flex flex-row align-items-start mb-3 ms-auto" style="border: 1px solid #D6B884; border-radius: 12px; width: 216px; height: 116px">
                  <div class="d-flex flex-column justify-content-between p-1" style="border-right: 1px solid #D6B884;  width: 120px; height: 100%;">
                      <p class="mb-3">Sub Total</p>
                      <p class="text-muted text-end">Rp.0,-</p>
                  </div>
                  <div class="d-flex flex-column justify-content-between p-1" style="border-right: 1px solid #D6B884;  width: 120px; height: 100%;">
                      <p class="mb-3">Diskon</p>
                      <p class="text-muted text-end">%</p>
                  </div>
                  <div class="d-flex flex-column justify-content-between p-1" style="width: 100%; height: 100%;">
                      <p>Faktur di bayar</p>
                      <p class="text-muted text-end">Rp.0,-</p>
                  </div>
              </div> -->
  
                  
                  
                  <div class="simpan-button d-flex justify-content-end mt-5">
                      <button type="submit" class="btn btn-primary me-2" style="width: 215px; border-radius: 12px;">Simpan</button>
                      <RouterLink to="customer" class="btn btn-outline-danger" style="width: 215px; border-radius: 12px; border: 1px solid #D6B884 !important;">Batal</RouterLink>
                  </div>
          </form>
      </div>
    </div>
  </div>

  <!-- <a href="javascript:void(0);" class="btn btn-adds" data-bs-toggle="modal" data-bs-target="#modaladditempurchase"
    ref="buttonopenmodaladditempurchase" hidden><i class="fa fa-plus me-2"></i></a>
  <additempurchasemodal :marketingdata="data_marketing" :kategoridata="data_kategori" :itemdata="data_item_filter"
    :warehousedata="data_warehouse" @id_kategori_selected="handleKategoriSelected"
    @id_item_selected="handleItemSelected" :isMarketing="isMarketing" @item_object="handleItemAdd"
    :itemEdited="itemEdited">
  </additempurchasemodal> -->


  
  <!-- Bootstrap Modal -->
  <div class="modal fade" id="fakturModal" tabindex="-1" aria-labelledby="fakturModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
              <div class="modal-header" style="background-color: #D6B884; color: white;">
                  <h5 class="modal-title" id="fakturModalLabel">Faktur Belum Lunas</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <!-- Modal content goes here -->
                  <p>Content for Cari Faktur search modal.</p>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>


  <!-- upload form -->
    <!-- <label class="mb-">Product Image</label>
    <div class="custom-file-container" data-upload-id="product-image">
      <label v-show="isGambar == true" @click="isGambar = false">Remove
        <a href="javascript:void(0)" class="custom-file-container__image-clear"
          title="Clear Image">x</a></label>
      <label class="custom-file-container__custom-file">
        <input type="file" @change="updateIsGambar()" ref="gambar"
          class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
        <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
        <span class="custom-file-container__custom-file__custom-file-control"></span>
      </label>
      <div class="custom-file-container__image-preview"></div>
    </div> -->


</template>


<style scoped>
  .order-total {

    color: #637381;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    border-right: 1px solid #f8f8f8;
    background: #fafbfe;
  }

  .order-amount {
    color: #212b36;
    font-size: 14px;
    padding: 10px;
    font-weight: 700;
    text-align: right;

  }

  .menu-top {
    background-color: rgba(214, 184, 132, 0.3); /* Adjust opacity and color as needed */
    border-left: 4px solid #D6B884; /* Primary color (Bootstrap default) */
    color: black; /* Dark text color (Bootstrap default) */
    font-weight: 600;
    border-radius: 0.125rem;
  }

  .custom-width {
    width: 400px;
  }

  .custom-select {
    width: 250px;
  }

  .custom-active {
    overflow: auto;
    max-height: 16rem;
  }

  .search-input {
      background-color: #F6F6F6;
      border: 1px solid #D6B884;
      color: #D6B884;
      border-radius: 4px;
  }

  .td-tabel {
    text-transform: none !important;
  }

  .form-control {
    text-transform: none !important;
  }

  /* Hide arrows for number input fields */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup,  goPage, AlertBottom, rupiah, rupiahInput, rupiahoutput, base_url_wilayah_indonesia, agamaList } from "@/assets/js/function";
// import modaladditem from "./modaladditem";
import { ModelSelect } from 'vue-search-select';

// import { Form, Field } from "vee-validate";
// import * as Yup from "yup";

export default {
  components: {
    ModelSelect
  },
  name: "edit Invoice",
  data() {
    return {
      filter: true,
      title: "Perbaharui Pelanggan",
      title1: "edit pelanggan",
      config: "",
      api: "",
      user_type: "",
      // foto_ktp_img: "",
      // googleMap: "",

      //data
      currentDate: new Date,
      currentData: {
        // suplier_detail: {
        //   id: "",
        // },

        daftar_kontak: [],
        daftar_bank: [
          {
            nama_bank: '',
            cabang: '',
            nomor_rekening: '',
            nama_account: '',
          }
        ],

        saldo_awal_hutang: '',
        batas_hutang_jatuh_tempo: '15',
        // invoice_detail: [],
        // invoice_payment: [],
        // discount_type: "rupiah",
        // calculated_discount: "0",
        // discount_amount: 0,
        // tax_amount: 0,
        // tax_rate: 0,
        // tax_name: "PPN",
        // tax_type: "persen",
        // grand_total: 0,
        // bank_name: "",
        // po_number: "",
        // code_project: "",
        // quotation_note: "",
        // quotation_term: "",
        // uang_muka: '',
        status: 'AKTIF',
        status_customer: 'AKTIF',


      },

      // items: [{
      //   nama_produk: '',
      //   sku: '',
      //   qty: '', 
      //   satuan: '', 
      //   harga: '',
      //   diskon: '',
      //   total_harga: '',
      // }],

      isNonAktif: false,

      statusList: [
        { label: "Aktif", value: "aktif" },
        { label: "Tidak Aktif", value: "tidak aktif" },
      ],

      agamaList,

      needData1: [],
      needData2: [],
      needData3: [],
      needData4: [],
      needData5: [],
      needData6: [],
      needData7: [],

      provinsiList: [],
      kabupatenList: [],
      kecamatanList: [],
      kelurahanList: [],

      provinsiList2: [],
      kabupatenList2: [],
      kecamatanList2: [],
      kelurahanList2: [],

      provinsiList3: [],
      kabupatenList3: [],
      kecamatanList3: [],
      kelurahanList3: [],
      
      // provinsiListLainnya: [],
      // kabupatenListLainnya: [],
      // kecamatanListLainnya: [],
      // kelurahanListLainnya: [],

      // provinsiListPajak: [],
      // kabupatenListPajak: [],
      // kecamatanListPajak: [],
      // kelurahanListPajak: [],


      // // cek deposit
      // status_item_deposit: false,

      // //cek payment
      // payment_active: "",

      // //test 
      // batas_payment_edit: 0,

      // //rate 
      // rate_active: "",

      // pass_data: {
      //   from: "",
      // },

      //bank
      // bankCek: false,
      // bank_selected: "",

      active_page: "umum",
      isActive: true,

      isSameAddress: false,
      isSameAddressPajak: false,

    };
  },
  components: {
      ModelSelect
  },
  computed: {
    // totalAmount() {

    //   if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
    //     return 0;
    //   } else {
    //     const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.item_total), 0)
    //     return total
    //   }
    // },
    // total_payment() {
    //   if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
    //     return 0;
    //   } else {
    //     const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.payment_amount), 0)
    //     return total
    //   }
    // },

    // batas_payment() {
    //   let payment = 0;
    //   let amount = 0;
    //   if (this.currentData.invoice_payment == undefined || this.currentData.invoice_payment.length == 0) {
    //     payment = 0;
    //   } else {
    //     const total = this.currentData.invoice_payment.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.payment_amount), 0)
    //     payment = total;
    //   }
    //   if (this.currentData.invoice_detail == undefined || this.currentData.invoice_detail.length == 0) {
    //     amount = 0;
    //   } else {
    //     const total = this.currentData.invoice_detail.reduce((accumulator, currentValue) => accumulator +
    //       parseInt(currentValue.item_total), 0)
    //     amount = total
    //   }


    //   return amount - payment;
    // }

  },
  watch: {
    'currentData.provinsi': {
      async handler(newValue) {
        const index = this.provinsiList.findIndex((record) => record.text === newValue);
        console.log('provinsi berubah')
        console.log('index provinsi :', index)

        await this.kabupaten_index(this.provinsiList[index].id);
        this.kecamatanList = [];
        this.kelurahanList = [];
      }
    },
    'currentData.kabupaten': {
      async handler(newValue) {
        const index = this.kabupatenList.findIndex((record) => record.text === newValue);
        console.log('index kabupaten :', index)
        await this.kecamatan_index(this.kabupatenList[index].id);
        this.kelurahanList = [];
      }
    },

    'currentData.kecamatan': {
      async handler(newValue) {
        const index = this.kecamatanList.findIndex((record) => record.text === newValue);
        console.log('index kecamatan :', index)

        await this.kelurahan_index(this.kecamatanList[index].id);
      }
    },


    // for alamat lainnya 
    'currentData.provinsi_lainnya': {
      async handler(newValue) {
        const index = this.provinsiList2.findIndex((record) => record.text === newValue);
        console.log('provinsi berubah')
        console.log('index provinsi :', index)

        await this.kabupaten_index2(this.provinsiList2[index].id);
        this.kecamatanList2 = [];
        this.kelurahanList2 = [];
      }
    },
    'currentData.kabupaten_lainnya': {
      async handler(newValue) {
        const index = this.kabupatenList2.findIndex((record) => record.text === newValue);
        console.log('index kabupaten :', index)
        await this.kecamatan_index2(this.kabupatenList2[index].id);
        this.kelurahanList2 = [];
      }
    },

    'currentData.kecamatan_lainnya': {
      async handler(newValue) {
        const index = this.kecamatanList2.findIndex((record) => record.text === newValue);
        console.log('index kecamatan :', index)

        await this.kelurahan_index2(this.kecamatanList2[index].id);
      }
    },


    // for pajak menu
    'currentData.provinsi_pajak': {
      async handler(newValue) {
        const index = this.provinsiList3.findIndex((record) => record.text === newValue);
        // console.log('ini cek watch provinsi', index)
        // console.log('ini cek watch provinsi: ', this.provinsiList3)
        // console.log('ini cek watch provinsi: ', this.kabupaten_index2)

        await this.kabupaten_index3(this.provinsiList3[index].id);
        this.kecamatanList3 = [];
        this.kelurahanList3 = [];
      }
    },

    'currentData.kabupaten_pajak': {
      async handler(newValue) {
        const index = this.kabupatenList3.findIndex((record) => record.text === newValue);
        // console.log('ini cek watch kabupaten', index)
        // console.log('ini cek watch kabupaten: ', this.kabupatenList3)
        // console.log('ini cek watch kabupaten: ', this.kecamatan_index2)
        await this.kecamatan_index3(this.kabupatenList3[index].id);
        this.kelurahanList3 = [];
      }
    },

    'currentData.kecamatan_pajak': {
      async handler(newValue) {
        const index = this.kecamatanList3.findIndex((record) => record.text === newValue);

        await this.kelurahan_index3(this.kecamatanList3[index].id);
      }
    },


    // 'currentData.provinsi': {
    //   handler(newValue) {
    //     const index = this.provinsiList.findIndex((record) => record.text === newValue);

    //     this.kabupaten_index(this.provinsiList[index].id);
    //     this.kecamatanList = [];
    //     this.kelurahanList = [];
    //   }
    // },
    // 'currentData.kabupaten': {
    //   handler(newValue) {
    //     const index = this.kabupatenList.findIndex((record) => record.text === newValue);

    //     this.kecamatan_index(this.kabupatenList[index].id);
    //     this.kelurahanList = [];
    //   }
    // },
    // 'currentData.kecamatan': {
    //   handler(newValue) {
    //     const index = this.kecamatanList.findIndex((record) => record.text === newValue);

    //     this.kelurahan_index(this.kecamatanList[index].id);
    //   }
    // },
  },

  created() {
    ShowLoading();
    const token = Cookies.get("token_refresh");

    this.config = {
      headers: {
        Authorization: `Bearer ` + token,
      },
      timeout: 30000,
    }

    this.user_type = localStorage.getItem('tipe_user')

    // get needed data
    // this.getData();
    
    // this.provinsi_index();
      // this.provinsi_index2();
    this.prepareData()
    CloseLoading();




  },

  methods: {
    rupiah,
    activePage(page) {
      this.active_page = page;
      localStorage.setItem("active_page", page)
    },

    toggleStatusAktif() {
       // Update the status based on the checkbox value
       this.currentData.status_customer = this.isNonAktif ? "TIDAK AKTIF" : "AKTIF";
    },

    validateDueDate() {
      if (!this.currentData.batas_hutang_jatuh_tempo || this.currentData.batas_hutang_jatuh_tempo <= 0) {
        this.currentData.batas_hutang_jatuh_tempo = 15;
      }
    },

  formatCurrency(label, number) {
      this.currentData[label] = rupiahInput(number);
  },

  openModal() {
      const modalElement = new bootstrap.Modal(this.$refs.fakturModal);
      modalElement.show();
  },
  closeModal() {
      const modalElement = bootstrap.Modal.getInstance(this.$refs.fakturModal);
      if (modalElement) modalElement.hide();
  },

    selectAll(event) {
      // Select all text when input field is focused
      event.target.select();
    },

    addNewRow() {
      this.currentData.daftar_kontak.push({
        nama: '',
        posisi: '',
        whatsapp: '',
        agama: '',
      });
    },

    async copyAddress() {
      // console.log('alamat sama jeng jeng')
      if (this.isSameAddress) {
        // Copy provinsi to provinsi2
          // Copy direct values
        this.currentData.alamat_lainnya= this.currentData.alamat_penagihan;
        this.currentData.kode_pos_lainnya= this.currentData.kode_pos;

        // Copy province and trigger dependent updates
        this.currentData.provinsi_lainnya= this.currentData.provinsi;
        const provinsiIndex = this.provinsiList.findIndex(
          (item) => item.text === this.currentData.provinsi
        );
        if (provinsiIndex !== -1) {
          await this.kabupaten_index2(this.provinsiList[provinsiIndex].id);
          console.log('ini index kabupaten 2 : ',  this.kabupatenList2)

          this.currentData.kabupaten_lainnya= this.currentData.kabupaten;
          const kabupatenIndex = this.kabupatenList2.findIndex(
            (item) => item.text === this.currentData.kabupaten
          );

          if (kabupatenIndex !== -1) {
            await this.kecamatan_index2(this.kabupatenList2[kabupatenIndex].id);
            console.log('ini index kecamatan 2 : ',  this.kecamatanList2)

            this.currentData.kecamatan_lainnya= this.currentData.kecamatan;
            const kecamatanIndex = this.kecamatanList2.findIndex(
              (item) => item.text === this.currentData.kecamatan
            );
            // console.log('ini kecamatannya :',kecamatanIndex)
            if (kecamatanIndex !== -1) {
              await this.kelurahan_index2(this.kecamatanList2[kecamatanIndex].id);
              // console.log('ini index kelurahan 2 : ',  this.kelurahanList2)

              this.currentData.kelurahan_lainnya= this.currentData.kelurahan;
              const kelurahanIndex = this.kelurahanList2.findIndex(
                (item) => item.text === this.currentData.kelurahan
              );
              // console.log('ini kecamatannya :',kecamatanIndex)
              if (kelurahanIndex !== -1) {
                // await this.kelurahan_index2(this.kelurahanList2[kecamatanIndex].id);
                console.log('ini index kelurahan 2 : ',  this.kelurahanList2[kecamatanIndex].id)

              }

            }

          }
          
        }
      } else {
        // Reset provinsi2 if the checkbox is unchecked
        this.currentData.alamat_lainnya= "";
        // this.currentData.provinsi_lainnya= "";
        // this.currentData.kabupaten_lainnya= "";
        // this.currentData.kecamatan_lainnya= "";
        // this.currentData.kelurahan_lainnya= "";
        this.currentData.kode_pos_lainnya= "";
      }
      // console.log('ini cek index provinsi copy:', provinsiIndex)
      // console.log('ini hasilnya kabupaten 2 : ',  this.provinsiList[provinsiIndex])

      // Copy kabupaten and trigger dependent updates
      

      // Copy kecamatan and trigger dependent updates
      // console.log('ini currentData kecamatan :' ,this.currentData.kecamatan)
      // console.log('ini currentData kecamatan listnya :' ,this.kecamatanList)
      

      // // Finally, copy kelurahan
      // this.currentData.kelurahan_pajak = this.currentData.kelurahan;
        // this.currentData.alamat2 = this.currentData.alamat_penagihan;
        // this.currentData.provinsi2 = this.currentData.provinsi;
        // this.currentData.kabupaten2 = this.currentData.kabupaten;
        // this.currentData.kecamatan2 = this.currentData.kecamatan;
        // this.currentData.kelurahan2 = this.currentData.kelurahan;
        // this.currentData.kode_pos2 = this.currentData.kode_pos;
      
    },


    async copyAddressPajak() {
      // console.log('alamat sama jeng jeng')
      if (this.isSameAddressPajak) {
        // Copy provinsi to provinsi2
          // Copy direct values
        this.currentData.alamat_pajak= this.currentData.alamat_penagihan;
        this.currentData.kode_pos_pajak= this.currentData.kode_pos;

        // Copy province and trigger dependent updates
        this.currentData.provinsi_pajak= this.currentData.provinsi;
        const provinsiIndex = this.provinsiList.findIndex(
          (item) => item.text === this.currentData.provinsi
        );
        if (provinsiIndex !== -1) {
          await this.kabupaten_index3(this.provinsiList[provinsiIndex].id);
          // console.log('ini index kabupaten 2 : ',  this.kabupatenList)

          this.currentData.kabupaten_pajak= this.currentData.kabupaten;
          const kabupatenIndex = this.kabupatenList3.findIndex(
            (item) => item.text === this.currentData.kabupaten
          );

          if (kabupatenIndex !== -1) {
            await this.kecamatan_index3(this.kabupatenList3[kabupatenIndex].id);
            // console.log('ini index kecamatan 2 : ',  this.kecamatanList)

            this.currentData.kecamatan_pajak= this.currentData.kecamatan;
            const kecamatanIndex = this.kecamatanList3.findIndex(
              (item) => item.text === this.currentData.kecamatan
            );
            // console.log('ini kecamatannya :',kecamatanIndex)
            if (kecamatanIndex !== -1) {
              await this.kelurahan_index3(this.kecamatanList3[kecamatanIndex].id);
              // console.log('ini index kelurahan 2 : ',  this.kelurahanList2)

              this.currentData.kelurahan_pajak= this.currentData.kelurahan;
              const kelurahanIndex = this.kelurahanList3.findIndex(
                (item) => item.text === this.currentData.kelurahan
              );
              // console.log('ini kecamatannya :',kecamatanIndex)
              if (kelurahanIndex !== -1) {
                // await this.kelurahan_index2(this.kelurahanList3[kecamatanIndex].id);
                console.log('ini index kelurahan 2 : ',  this.kelurahanList3[kecamatanIndex].id)

              }

            }

          }
          
        }
      } else {
        // Reset provinsi2 if the checkbox is unchecked
        this.currentData.alamat_pajak= "";
        // this.currentData.provinsi_pajak= "";
        // this.currentData.kabupaten_pajak= "";
        // this.currentData.kecamatan_pajak= "";
        // this.currentData.kelurahan_pajak= "";
        this.currentData.kode_pos_pajak= "";
      }
      // console.log('ini cek index provinsi copy:', provinsiIndex)
      // console.log('ini hasilnya kabupaten 2 : ',  this.provinsiList[provinsiIndex])

      // Copy kabupaten and trigger dependent updates
      

      // Copy kecamatan and trigger dependent updates
      // console.log('ini currentData kecamatan :' ,this.currentData.kecamatan)
      // console.log('ini currentData kecamatan listnya :' ,this.kecamatanList)
      

      // // Finally, copy kelurahan
      // this.currentData.kelurahan_pajak = this.currentData.kelurahan;
        // this.currentData.alamat2 = this.currentData.alamat_penagihan;
        // this.currentData.provinsi2 = this.currentData.provinsi;
        // this.currentData.kabupaten2 = this.currentData.kabupaten;
        // this.currentData.kecamatan2 = this.currentData.kecamatan;
        // this.currentData.kelurahan2 = this.currentData.kelurahan;
        // this.currentData.kode_pos2 = this.currentData.kode_pos;
      
    },

    async handleProvinsi(active_page) {
        if(active_page === 'alamat') {

          this.kabupatenList = [];
          this.kecamatanList = [];
          this.kelurahanList = [];

        }else if(active_page === 'alamat_lainnya') {
          this.kabupatenListLainnya = [];
          this.kecamatanListLainnya = [];
          this.kelurahanListLainnya = [];

        }else if(active_page === 'alamat_pajak'){
          this.kabupatenListPajak = [];
          this.kecamatanListPajak = [];
          this.kelurahanListPajak = [];
        }


        // Clear the selected city, district, and sub-district from the address as well
        if( active_page === 'alamat') {

          this.currentData.kabupaten = null;
          this.currentData.kecamatan = null;
          this.currentData.kelurahan = null;
        } else if( active_page === 'alamat_lainnya') {

          this.currentData.kabupaten_lainnya = null;
          this.currentData.kecamatan_lainnya = null;
          this.currentData.kelurahan_lainnya = null;

        } else if(active_page === 'alamat_pajak') {
          this.currentData.kabupaten_pajak = null;
          this.currentData.kecamatan_pajak = null;
          this.currentData.kelurahan_pajak = null;
        };

        // const indexProvinsi = this.provinsiList.findIndex((record) => record.id === this.currentData.daftar_alamat_muat[index].province.id);
        //     console.log(indexProvinsi)
        //     if (indexProvinsi > -1) {
        //         this.kabupaten_index(this.provinsiList[indexProvinsi].id);
        //     }
        let province = '';
        if(active_page === 'alamat'){
          province = this.currentData.provinsi;

        }else if(active_page === 'alamat_lainnya') {
          province = this.currentData.provinsi_lainnya;

        } else if (active_page === 'alamat_pajak') {

          province = this.currentData.provinsi_pajak;

        }
        
        if (province ) { // Ensure province exists and has an id
            const indexProvinsi = this.provinsiList.findIndex((record) => record.text == province);
            // console.log(indexProvinsi);
            if (indexProvinsi > -1) {
                this.kabupaten_index(this.provinsiList[indexProvinsi].id, active_page);
            }
        }



    },

    async handleKabupaten(active_page) {
      
      if(active_page == 'alamat') {

        this.kecamatanList = [];
        this.kelurahanList = [];

      } else if(active_page == 'alamat_lainnya') {
        this.kecamatanListLainnya = [];
        this.kelurahanListLainnya = [];
      }

      if(active_page === 'alamat') {
        this.currentData.kecamatan = null;
        this.currentData.kelurahan = null;
      }else if( active_page === 'alamat_lainnya') {

        this.currentData.kecamatan_lainnya = null;
        this.currentData.kelurahan_lainnya = null;
      } else if(active_page === 'alamat_pajak') {

        this.currentData.kecamatan_pajak = null;
        this.currentData.kelurahan_pajak = null;
      }
      

      // const indexCity = this.kabupatenList.findIndex((record) => record.id === this.currentData.daftar_alamat_muat[index].city.id);
      //     // console.log(index)
      //     if (indexCity > -1) {
      //         this.kecamatan_index(this.kabupatenList[indexCity].id);
      //     }
      let kabupaten = '';

      if(active_page === 'alamat'){
        kabupaten = this.currentData.kabupaten;

      }else if(active_page === 'alamat_lainnya'){

        kabupaten = this.currentData.kabupaten_lainnya;

      } else if(active_page === 'alamat_pajak'){

        kabupaten = this.currentData.kabupaten_pajak;

      }

      if (kabupaten ) { // Ensure city exists and has an id
          const indexCity = this.kabupatenList.findIndex((record) => record.text == kabupaten);

          if (indexCity > -1) {
              this.kecamatan_index(this.kabupatenList[indexCity].id, active_page);
          }
      }
      // console.log('ini kabupatennya :', kabupaten)




    },

    async handleKecamatan(active_page) {
      // this.kecamatanList = [];
      if(active_page == 'alamat'){

        this.kelurahanList = [];
      }else if(active_page == 'alamat_lainnya'){
        
        this.kelurahanListLainnya = [];
      }else if(active_page == 'alamat_pajak'){
        this.kelurahanListPajak = [];
      }

      // this.currentData.daftar_alamat_muat[index].kecamatan = null;
      
      if(active_page === 'alamat') {
        this.currentData.kelurahan = null;
        
      }else if( active_page === 'alamat_lainnya') {
        this.currentData.kelurahan_lainnya = null;

      } else if( active_page === 'alamat_pajak' ) {
        this.currentData.kelurahan_pajak = null;

      }

      // const indexCity = this.kabupatenList.findIndex((record) => record.id === this.currentData.city.id);
      //     // console.log(index)
      //     if (indexCity > -1) {
      //         this.kecamatan_index(this.kabupatenList[indexCity].id);
      //     }

      let kecamatan = '';

      if(active_page === 'alamat'){
        kecamatan = this.currentData.kecamatan;

      }else if(active_page === 'alamat_lainnya'){

        kecamatan = this.currentData.kecamatan_lainnya;

      }else if(active_page === 'alamat_pajak'){

        kecamatan = this.currentData.kecamatan_pajak;
      }
      
      // console.log('ini kecamatannya :', kecamatan)

      if (kecamatan ) { // Ensure city exists and has an id
          const indexKecamatan = this.kecamatanList.findIndex((record) => record.text == kecamatan);

          if (indexKecamatan > -1) {
              this.kelurahan_index(this.kecamatanList[indexKecamatan].id, active_page);
          }
      }
    },


    goPageCallback(url, callback) {
      localStorage.setItem('callback', callback);
      goPage(url);

    },




    async prepareData() {
      
      this.currentData = JSON.parse(localStorage.getItem('currentData'));

      if(this.currentData.daftar_bank.length == 0 ){
        this.currentData.daftar_bank.push({
          nama: '',
          cabang: '',
          nomor_rekening: '',
          nama_account: '',
        })
      }

      // customer(old) / bidang industri
      const needData1 = JSON.parse(localStorage.getItem('needData1'));
      console.log(needData1)
      needData1.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        console.log(element)
        this.needData1.push(element);
      });


      // invoice(old)/ syarat pembayaran
      const needData2 = JSON.parse(localStorage.getItem('needData2'));
      needData2.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        console.log(element)
        this.needData2.push(element);
      });

      // payment(old)/ mata uang
      const needData3 = JSON.parse(localStorage.getItem('needData3'));
      needData3.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        console.log(element)
        this.needData3.push(element);
      });

      // pajak
      const needData4 = JSON.parse(localStorage.getItem('needData4'));
      needData4.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        console.log(element)
        this.needData4.push(element);
      });

      // tipe pajak
      const needData5 = JSON.parse(localStorage.getItem('needData5'));
      needData5.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        console.log(element)
        this.needData5.push(element);
      });

      // metode pembayaran
      const needData6 = JSON.parse(localStorage.getItem('needData6'));
      needData6.forEach((item, index) => {
        const element = { value: item.id, text: item.nama }
        console.log(element)
        this.needData6.push(element);
      });

      // for agama list
      const needData7 = this.agamaList;
      needData7.forEach((item, index) => {
        const element = { value: item.id, text: item.text }
        console.log(element)
        this.needData7.push(element);
      });


      const index = needData1.findIndex((item) => item.id == this.currentData.jenis_industri);
      console.log(this.currentData.jenis_industri)
      console.log('ini id:', this.currentData.jenis_industri)
      console.log('ini option', needData1)
      console.log(index)
      if ( index > -1) {
        this.currentData.jenis_industri = this.needData1[index].value;
      }

      const indexSyaratBayar = this.needData2.findIndex((item) => item.id === this.currentData.syarat_pembayaran);
      console.log(this.currentData.syarat_pembayaran)
      // console.log(this.currentData.jenis_industri_id)
      // console.log(needData2)
      // console.log(indexSyaratBayar)
      if ( indexSyaratBayar > -1) {
        this.currentData.syarat_pembayaran = this.needData2[indexSyaratBayar].value;
      }

      const indexMataUang = needData3.findIndex((item) => item.id == this.currentData.mata_uang);
      // console.log('ini mata uang :', this.currentData.mata_uang)
      // console.log('ini mata uang:', needData3)
      // console.log('ini mata uang:',indexMataUang )
      if ( indexMataUang > -1) {
        this.currentData.mata_uang = this.needData3[indexMataUang].value;
      }

      const indexPajak = needData4.findIndex((item) => item.id == this.currentData.pajak);
      // console.log(this.currentData.mata_uang)
      // console.log(this.currentData.jenis_industri_id)
      // console.log(needData4)
      // console.log(indexPajak )
      if ( indexPajak > -1) {
        this.currentData.pajak = this.needData4[indexPajak].value;
      }

      const indexTipePajak = needData5.findIndex((item) => item.id == this.currentData.tipe_pajak);
      // console.log(this.currentData.mata_uang)
      // console.log(this.currentData.jenis_industri_id)
      // console.log(needData5)
      // console.log(indexTipePajak)
      if ( indexTipePajak > -1) {
        this.currentData.tipe_pajak = this.needData5[indexTipePajak].value;
      }

      const indexMetodeBayar = this.needData6.findIndex((item) => item.id == this.currentData.metode_pembayaran);
      // console.log(this.currentData.metode_pembayaran_id)
      // console.log('ini id:', this.currentData.metode_pembayaran_id)
      // console.log('ini option', needData6)
      // console.log(indexMetodeBayar)
      if ( indexMetodeBayar > -1) {
        this.currentData.metode_pembayaran = this.needData6[indexMetodeBayar].value;
      }

      this.currentData.daftar_kontak.forEach((contact) => {
        const indexAgama = this.needData7.findIndex((item) => item.value == contact.agama);
        if (indexAgama > -1) {
          contact.agama = this.needData7[indexAgama].value;  // Set the value based on the id match
        }
      });

      // =============================================================
      // for alamat penagihan , alamat lainnya, alamat pajak


      await this.provinsi_index();

      const indexProvinsi = this.provinsiList.findIndex((record) => record.text === this.currentData.provinsi);
      
      if (indexProvinsi > -1) {

          this.currentData.provinsi = this.provinsiList[indexProvinsi]; // Set the province object

          await this.kabupaten_index(this.provinsiList[indexProvinsi].id, 'alamat'); // Load city (kabupaten) list for the province

          // Find the index of the city (kabupaten) in the city list
          const indexKabupaten = this.kabupatenList.findIndex((record) => record.text === this.currentData.kabupaten);
          console.log('ini index kabupaten :', indexKabupaten)
          console.log('ini index current data kabupaten :', this.currentData.kabupaten)
          if (indexKabupaten > -1) {
              this.currentData.kabupaten = this.kabupatenList[indexKabupaten]; // Set the city object
              await this.kecamatan_index(this.kabupatenList[indexKabupaten].id, 'alamat'); // Load district (kecamatan) list for the city

              // Find the index of the district (kecamatan) in the district list
              const indexKecamatan = this.kecamatanList.findIndex((record) => record.text === this.currentData.kecamatan);
              if (indexKecamatan > -1) {
                  this.currentData.kecamatan = this.kecamatanList[indexKecamatan]; // Set the district object
                  await this.kelurahan_index(this.kecamatanList[indexKecamatan].id, 'alamat'); // Load sub-district (kelurahan) list for the district

                  // Find the index of the sub-district (kelurahan) in the sub-district list
                  const indexKelurahan = this.kelurahanList.findIndex((record) => record.text === this.currentData.kelurahan);
                  if (indexKelurahan > -1) {
                      this.currentData.kelurahan = this.kelurahanList[indexKelurahan]; // Set the sub-district object
                  }
              }
          }
      }

      await this.provinsi_index2();


      const indexProvinsiLainnya = this.provinsiList2.findIndex((record) => record.text === this.currentData.provinsi_lainnya);
      if (indexProvinsiLainnya > -1) {

          this.currentData.provinsi_lainnya = this.provinsiList2[indexProvinsiLainnya]; // Set the province object
          await this.kabupaten_index2(this.provinsiList2[indexProvinsiLainnya].id, 'alamat_lainnya'); // Load city (kabupaten) list for the province
          // Find the index of the city (kabupaten) in the city list
          const indexKabupatenLainnya = this.kabupatenList2.findIndex((record) => record.text === this.currentData.kabupaten_lainnya);
          console.log('ini index kabupaten lainnya :', indexKabupatenLainnya)
          console.log('ini index current data kabupaten lainnya:', this.currentData.kabupaten_lainnya)
          if (indexKabupatenLainnya > -1) {
              this.currentData.kabupaten_lainnya = this.kabupatenList2[indexKabupatenLainnya]; // Set the city object
              await this.kecamatan_index2(this.kabupatenList2[indexKabupatenLainnya].id, 'alamat_lainnya'); // Load district (kecamatan) list for the city

              // Find the index of the district (kecamatan) in the district list
              const indexKecamatanLainnya = this.kecamatanList2.findIndex((record) => record.text === this.currentData.kecamatan_lainnya);
              if (indexKecamatanLainnya > -1) {
                  this.currentData.kecamatan_lainnya = this.kecamatanList2[indexKecamatanLainnya]; // Set the district object
                  await this.kelurahan_index2(this.kecamatanList2[indexKecamatanLainnya].id, 'alamat_lainnya'); // Load sub-district (kelurahan) list for the district

                  // Find the index of the sub-district (kelurahan) in the sub-district list
                  const indexKelurahanLainnya = this.kelurahanList2.findIndex((record) => record.text === this.currentData.kelurahan_lainnya);
                  if (indexKelurahanLainnya > -1) {
                      this.currentData.kelurahan_lainnya = this.kelurahanList2[indexKelurahanLainnya]; // Set the sub-district object
                  }
              }
          }
      }

      await this.provinsi_index3();

      const indexProvinsiPajak = this.provinsiList3.findIndex((record) => record.text === this.currentData.provinsi_pajak);
      if (indexProvinsiPajak > -1) {

          this.currentData.provinsi_pajak = this.provinsiList3[indexProvinsiPajak]; // Set the province object
          await this.kabupaten_index3(this.provinsiList3[indexProvinsiPajak].id, 'alamat_pajak'); // Load city (kabupaten) list for the province
          // Find the index of the city (kabupaten) in the city list
          const indexKabupatenPajak = this.kabupatenList3.findIndex((record) => record.text === this.currentData.kabupaten_pajak);
          console.log('ini index kabupaten lainnya :', indexKabupatenPajak)
          console.log('ini index current data kabupaten lainnya:', this.currentData.kabupaten_pajak)
          if (indexKabupatenPajak > -1) {
              this.currentData.kabupaten_pajak = this.kabupatenList3[indexKabupatenPajak]; // Set the city object
              await this.kecamatan_index3(this.kabupatenList3[indexKabupatenPajak].id, 'alamat_pajak'); // Load district (kecamatan) list for the city

              // Find the index of the district (kecamatan) in the district list
              const indexKecamatanPajak = this.kecamatanList3.findIndex((record) => record.text === this.currentData.kecamatan_pajak);
              if (indexKecamatanPajak > -1) {
                  this.currentData.kecamatan_pajak = this.kecamatanList3[indexKecamatanPajak]; // Set the district object
                  await this.kelurahan_index2(this.kecamatanList3[indexKecamatanPajak].id, 'alamat_pajak'); // Load sub-district (kelurahan) list for the district

                  // Find the index of the sub-district (kelurahan) in the sub-district list
                  const indexKelurahanPajak = this.kelurahanList3.findIndex((record) => record.text === this.currentData.kelurahan_pajak);
                  if (indexKelurahanPajak > -1) {
                      this.currentData.kelurahan_pajak = this.kelurahanList3[indexKelurahanPajak]; // Set the sub-district object
                  }
              }
          }
      }

      
      // const indexProvinsiLainnya = this.provinsiListLainnya.findIndex((record) => record.text === this.currentData.provinsi_lainnya);
      // if (indexProvinsiLainnya > -1) {

      //     this.currentData.provinsi_lainnya = this.provinsiListLainnya[indexProvinsiLainnya]; // Set the province object
      //     await this.kabupaten_index(this.provinsiListLainnya[indexProvinsiLainnya].id, 'alamat_lainnya'); // Load city (kabupaten) list for the province
      //     // Find the index of the city (kabupaten) in the city list
      //     const indexKabupatenLainnya = this.kabupatenListLainnya.findIndex((record) => record.text === this.currentData.kabupaten_lainnya);
      //     console.log('ini index kabupaten lainnya :', indexKabupatenLainnya)
      //     console.log('ini index current data kabupaten lainnya:', this.currentData.kabupaten_lainnya)
      //     if (indexKabupatenLainnya > -1) {
      //         this.currentData.kabupaten_lainnya = this.kabupatenListLainnya[indexKabupatenLainnya]; // Set the city object
      //         await this.kecamatan_index(this.kabupatenListLainnya[indexKabupatenLainnya].id, 'alamat_lainnya'); // Load district (kecamatan) list for the city

      //         // Find the index of the district (kecamatan) in the district list
      //         const indexKecamatanLainnya = this.kecamatanListLainnya.findIndex((record) => record.text === this.currentData.kecamatan_lainnya);
      //         if (indexKecamatanLainnya > -1) {
      //             this.currentData.kecamatan_lainnya = this.kecamatanListLainnya[indexKecamatanLainnya]; // Set the district object
      //             await this.kelurahan_index(this.kecamatanListLainnya[indexKecamatanLainnya].id, 'alamat_lainnya'); // Load sub-district (kelurahan) list for the district

      //             // Find the index of the sub-district (kelurahan) in the sub-district list
      //             const indexKelurahanLainnya = this.kelurahanListLainnya.findIndex((record) => record.text === this.currentData.kelurahan_lainnya);
      //             if (indexKelurahanLainnya > -1) {
      //                 this.currentData.kelurahan_lainnya = this.kelurahanListLainnya[indexKelurahanLainnya]; // Set the sub-district object
      //             }
      //         }
      //     }
      // }

      // const indexProvinsiPajak = this.provinsiListPajak.findIndex((record) => record.text === this.currentData.provinsi_pajak);
      // if (indexProvinsiPajak > -1) {

      //     this.currentData.provinsi_pajak = this.provinsiListPajak[indexProvinsiPajak]; // Set the province object
      //     await this.kabupaten_index(this.provinsiListPajak[indexProvinsiPajak].id, 'alamat_pajak'); // Load city (kabupaten) list for the province
      //     // Find the index of the city (kabupaten) in the city list
      //     const indexKabupatenPajak = this.kabupatenListPajak.findIndex((record) => record.text === this.currentData.kabupaten_pajak);
      //     console.log('ini index kabupaten lainnya :', indexKabupatenPajak)
      //     console.log('ini index current data kabupaten lainnya:', this.currentData.kabupaten_pajak)
      //     if (indexKabupatenPajak > -1) {
      //         this.currentData.kabupaten_pajak = this.kabupatenListPajak[indexKabupatenPajak]; // Set the city object
      //         await this.kecamatan_index(this.kabupatenListPajak[indexKabupatenPajak].id, 'alamat_pajak'); // Load district (kecamatan) list for the city

      //         // Find the index of the district (kecamatan) in the district list
      //         const indexKecamatanPajak = this.kecamatanListPajak.findIndex((record) => record.text === this.currentData.kecamatan_pajak);
      //         if (indexKecamatanPajak > -1) {
      //             this.currentData.kecamatan_pajak = this.kecamatanListPajak[indexKecamatanPajak]; // Set the district object
      //             await this.kelurahan_index(this.kecamatanListPajak[indexKecamatanPajak].id, 'alamat_pajak'); // Load sub-district (kelurahan) list for the district

      //             // Find the index of the sub-district (kelurahan) in the sub-district list
      //             const indexKelurahanPajak = this.kelurahanListPajak.findIndex((record) => record.text === this.currentData.kelurahan_pajak);
      //             if (indexKelurahanPajak > -1) {
      //                 this.currentData.kelurahan_pajak = this.kelurahanListPajak[indexKelurahanPajak]; // Set the sub-district object
      //             }
      //         }
      //     }
      // }


      
      // Check if "Sama Dengan Alamat Penagihan" (isSameAddress) is set
      if (
        this.currentData.alamat_lainnya === this.currentData.alamat_penagihan &&
        this.currentData.kode_pos_lainnya === this.currentData.kode_pos &&
        this.currentData.alamat_lainnya !== '' &&
        this.currentData.alamat_penagihan !== '' &&
        this.currentData.kode_pos_lainnya !== '' &&
        this.currentData.kode_pos !== ''
      ) {
        this.isSameAddress = true;
      } else {
        this.isSameAddress = false;
      }

      // Check if "Alamat Pajak Sama Dengan Alamat Penagihan" (isSameAddressPajak) is set
      if (
        this.currentData.alamat_pajak === this.currentData.alamat_penagihan &&
        this.currentData.kode_pos_pajak === this.currentData.kode_pos &&
        this.currentData.alamat_pajak !== '' &&
        this.currentData.alamat_penagihan !== '' &&
        this.currentData.kode_pos_pajak !== '' &&
        this.currentData.kode_pos !== ''
      ) {
        this.isSameAddressPajak = true;
      } else {
        this.isSameAddressPajak = false;
      }

      this.currentData.saldo_awal_hutang = rupiahInput(this.currentData.saldo_awal_hutang )
            
      this.isNonAktif = this.currentData.status_customer === "TIDAK AKTIF";



    },
    
    // handleKategoriSelected(item) {
    //   if (item != "") {
    //     this.data_item_filter = this.dataItemsFilterKategori(item);
    //   } else {
    //     this.data_item_filter = this.data_item;
    //   }

    // },

    // dataItemsFilterKategori(id) {
    //   return this.data_item.filter(item => {
    //     // Apply your filtering condition
    //     if (item.id_kategori == id) {
    //       // Modify the 'value' attribute here
    //       item.text = item.nama;// Your modification logic here;
    //       return true; // Keep this item in the filtered result
    //     }
    //     return false; // Exclude this item from the filtered result
    //   });
    // },
    // handleItemSelected(item) {
    //   this.isMarketing = item;
    // },
    // handleItemAdd(item) {

    //   const index = this.data_item.findIndex((record) => record.id == item.id_items);
    //   const indexMarketing = this.data_marketing.findIndex((record) => record.id == item.id_marketing);
    //   const indexWarehouse = this.data_warehouse.findIndex((record) => record.id == item.id_warehouse);

    //   const indexKeranjangUpdate = this.currentData.invoice_detail.findIndex(
    //     (record) => record.id == item.id
    //   );

    //   const indexWarehouseInvoiceDetail = this.currentData.invoice_detail.findIndex(
    //     (record) => record.id_warehouse == item.id_warehouse && record.id_items == item.id_items
    //   );


    //   const rate_active = this.cek_rate_active();
    //   let nama_marketing = "";
    //   if (this.data_item[index].ismarketing == 'AKTIF' && this.data_marketing[indexMarketing] != undefined) {
    //     nama_marketing = this.data_marketing[indexMarketing].fullname;
    //   }

    //   let total_harga_item = 0;
    //   let total_discount = 0;
    //   if (item.discount_type == 'percent') {
    //     total_harga_item = item.harga - (item.harga * (item.discount / 100));
    //     total_discount = (item.harga * (item.discount / 100));
    //   } else {
    //     total_harga_item = item.harga - item.discount;
    //     total_discount = item.discount;
    //   }


    //   const itemPrepare = {
    //     ...item, name: this.data_item[index].nama,
    //     isdeposit: this.data_item[index].isdeposit,
    //     on_bungkus: this.data_item[index].on_bungkus,
    //     deskripsi: this.data_item[index].deskripsi,
    //     nama_marketing: nama_marketing,
    //     rate: item.harga,
    //     discount_type: item.discount_type,
    //     discount_amount: item.discount,
    //     calculated_discount: total_discount,
    //     item_total: item.qty * parseInt(total_harga_item),
    //     id_warehouse: item.id_warehouse,
    //     nama_gudang: this.data_warehouse[indexWarehouse].text,
    //   };

    //   this.cek_deposit();

    //   if (item.status == 'tambah') {

    //     if (indexWarehouseInvoiceDetail > -1) {
    //       AlertPopup('error', '', 'Produk dengan gudang tersebut sudah terdaftar', 2000, true);
    //       return true;
    //     }

    //     itemPrepare.id = this.currentData.invoice_detail.length;

    //     if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK') {
    //       this.currentData.invoice_detail.push(itemPrepare);
    //     } else if (this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF') {
    //       this.currentData.invoice_detail.push(itemPrepare);
    //     } else if (this.status_item_deposit == false && this.currentData.invoice_detail.length == 0 && this.data_item[index].isdeposit == 'AKTIF') {
    //       this.currentData.invoice_detail.push(itemPrepare);
    //     }
    //     else {
    //       AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
    //     }
    //   } else {




    //     if (this.status_item_deposit == false && this.data_item[index].isdeposit == 'TIDAK' && indexKeranjangUpdate > -1) {
    //       this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;
    //     } else if ((this.status_item_deposit == true && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1) || ((this.status_item_deposit == false && this.data_item[index].isdeposit == 'AKTIF' && indexKeranjangUpdate > -1 && this.currentData.invoice_detail.length == 1))) {
    //       if (this.data_item[index].isdeposit == 'AKTIF' && this.currentData.invoice_detail.length == 1) {
    //         this.currentData.invoice_payment = [];
    //       }
    //       this.currentData.invoice_detail[indexKeranjangUpdate] = itemPrepare;

    //     } else if (indexKeranjangUpdate == -1) {
    //       AlertPopup('error', '', 'item tidak ditemukan', 2000, true);
    //     }
    //     else {
    //       AlertPopup('error', '', 'item marketing atau item deposit tidak bisa disatukan didalam satu invoice', 2000, true);
    //     }
    //   }
    //   this.cek_deposit();

    //   this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;

    // },
    
    // deleteItem(indexToRemove) {
    //   this.currentData.invoice_detail.splice(indexToRemove, 1);
    //   this.cek_deposit();
    //   if (this.currentData.invoice_detail.length == 0) {
    //     this.currentData.invoice_payment = [];
    //   }
    //   this.currentData.grand_total = this.totalAmount - this.currentData.discount_amount + this.currentData.tax_amount;
    // },

    // getData() {

    //   // this.data_suplier.push({ id: 0, text: 'Walk in', rate: "0", value: "0" });

    //   const suplier = JSON.parse(localStorage.getItem('suplier'));
    //   suplier.forEach((item, index) => {
    //     let item_prepare = { ...item, value: item.id, text: item.fullname };
    //     this.data_suplier.push(item_prepare)
    //   });




    //   //get data kategori 
    //   const kategori = JSON.parse(localStorage.getItem('kategori'));
    //   kategori.forEach((item, index) => {
    //     let item_prepare = { ...item, value: item.id, text: item.nama };
    //     this.data_kategori.push(item_prepare)
    //   });

    //   const item = JSON.parse(localStorage.getItem('item'));
    //   item.forEach((item, index) => {
    //     let item_prepare = { ...item, value: item.id, text: item.nama, };
    //     this.data_item.push(item_prepare)
    //   });

    //   console.log(this.data_item);

    //   // const list_items_in_warehouse = JSON.parse(localStorage.getItem('list_items_in_warehouse'));
    //   // list_items_in_warehouse.forEach((item, index) => {
    //   //   let item_prepare = { ...item, value: item.id, text: item.id_warehouse, };
    //   //   this.data_list_items_in_warehouse.push(item_prepare)
    //   // });

    //   const data_warehouse = JSON.parse(localStorage.getItem('warehouse'));
    //   data_warehouse.forEach((item, index) => {
    //     let item_prepare = { ...item, value: item.id, text: item.nama, };
    //     this.data_warehouse.push(item_prepare)
    //   });

    //   const data_ekspedisi = JSON.parse(localStorage.getItem('ekspedisi'));
    //   data_ekspedisi.forEach((item, index) => {
    //     let item_prepare = { ...item, value: item.id, text: item.nama, };
    //     this.data_ekspedisi.push(item_prepare)
    //   });





    //   if (localStorage.getItem('pass_data') != null) {
    //     this.pass_data = JSON.parse(localStorage.getItem('pass_data'));

    //     if (this.pass_data.from == 'suplier') {
    //       const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
    //       this.data_suplier.unshift(item_prepare);

    //     } else if (this.pass_data.from == 'marketing') {
    //       const item_prepare = { value: this.pass_data.id, text: this.pass_data.nama };
    //       this.data_marketing.unshift(item_prepare);

    //     }

    //   }

    //   if (localStorage.getItem('pass_data') != null) {
    //     if (this.pass_data.from == 'marketing') {
    //       localStorage.setItem('marketing', JSON.stringify(this.data_marketing));
    //     } else if (this.pass_data.from == 'suplier') {
    //       localStorage.setItem('suplier', JSON.stringify(this.data_suplier));
    //     }
    //     localStorage.removeItem('pass_data');
    //   }




    // },

    // addItem() {
    //   if (this.currentData.suplier_detail.id == '') {
    //     AlertPopup('error', '', 'Silahkan Pilih Pemasok terebih dahulu ', 2000, true);
    //     return true;
    //   }

    //   this.itemEdited = {
    //     marketing_id: "",
    //     kategori_id: "",
    //     id_warehouse: "",
    //     item_id: "",
    //     qty: 1,
    //     status: 'tambah',
    //     discount_type: 'percent',
    //     harga: 0,
    //     discount: 0,
    //   }



    //   const openButton = this.$refs.buttonopenmodaladditempurchase;
    //   openButton.click();

    // },
    // editItem(item) {

    //   const index = this.data_item.findIndex((record) => record.id == item.id_items);
    //   const indexKategori = this.data_kategori.findIndex((record) => record.id == this.data_item[index].id_kategori);

    //   this.itemEdited = {
    //     marketing_id: item.id_marketing,
    //     kategori_id: this.data_kategori[indexKategori].id,
    //     item_id: item.id_items,
    //     qty: item.qty,
    //     status: 'edit',
    //     harga: parseInt(item.rate),
    //     discount_type: item.discount_type,
    //     discount_amount: parseInt(item.discount_amount),
    //     id_warehouse: item.id_warehouse,
    //     id: item.id,
    //   }
    //   const openButton = this.$refs.buttonopenmodaladditempurchase;
    //   openButton.click();
    // },



    //payment
    // handlePaymentAdd(item) {

    //   const indexKeranjangUpdate = this.currentData.invoice_payment.findIndex(
    //     (record) => record.id == item.id
    //   );
    //   item.payment_method = this.data_payments[item.payment_method].nama;

    //   if (item.status == 'tambah') {
    //     item.id = this.currentData.invoice_payment.length;

    //     this.currentData.invoice_payment.push(item);
    //   } else {
    //     this.currentData.invoice_payment[indexKeranjangUpdate] = item;
    //   }
    // },


    

    // convertDate(value) {
    //   const date = new Date(value);

    //   // Use built-in methods to get year, month, and day
    //   const year = date.getFullYear();
    //   const month = String(date.getMonth() + 1).padStart(2, '0');
    //   const day = String(date.getDate()).padStart(2, '0');
    //   return `${year}-${month}-${day}`;
    // },

    insert() {
      ShowLoading();
      let formData = new FormData();

      Object.entries(this.currentData).forEach(([key, value]) => {
        formData.append(key, value);
      });


      formData.delete("daftar_kontak")
      formData.append('daftar_kontak', JSON.stringify(this.currentData.daftar_kontak));
      formData.delete("daftar_bank")
      formData.append('daftar_bank', JSON.stringify(this.currentData.daftar_bank));

      formData.delete("saldo_awal_hutang");
      formData.append("saldo_awal_hutang", rupiahoutput(this.currentData.saldo_awal_hutang));

      if(!this.currentData.nama_perusahaan || this.currentData.nama_perusahaan === ''){
        AlertPopup("error", "Validation Error", "Nama Perusahaan tidak boleh kosong!", 1500, false)
        return
      }else if (!this.currentData.jenis_industri || this.currentData.jenis_industri === ''){
        AlertPopup("error", "Validation Error", "Bidang industri harus dipilih!", 1500, false)
        return
      }else if (!this.currentData.metode_pembayaran || this.currentData.metode_pembayaran === ''){
        AlertPopup("error", "Validation Error", "Metode Pembayaran harus dipilih!", 1500, false)
        return
      }else if (this.currentData.daftar_kontak.length === 0) {
        AlertPopup("error", "Validation Error", "Daftar kontak harus ada minimal 1 kontak!", 1500, false);
        return;
      }else if(!this.currentData.alamat_penagihan || this.currentData.alamat_penagihan === '' || this.currentData.alamat_penagihan.length < 5){
          AlertPopup("error", "Validation Error", "Alamat Penagihan wajib dilengkapi!", 1500, false);
          return;
        }else if(!this.currentData.kode_pos || String(this.currentData.kode_pos).length < 5){
          AlertPopup("error", "Validation Error", "Kode Pos penagihan masih kosong / kurang dari 5 digit!", 1500, false);
          return;
        }else if(!this.currentData.provinsi || this.currentData.provinsi === ''){
          AlertPopup("error", "Validation Error", "Provinsi wajib dipilih!", 1500, false);
          return;
        }else if(!this.currentData.kabupaten || this.currentData.kabupaten === ''){
          AlertPopup("error", "Validation Error", "Kabupaten wajib dipilih!", 1500, false);
          return;
        }else if(!this.currentData.kecamatan || this.currentData.kecamatan === ''){
          AlertPopup("error", "Validation Error", "Kecamatan wajib dipilih!", 1500, false);
          return;
        }else if(!this.currentData.kelurahan || this.currentData.kelurahan === ''){
          AlertPopup("error", "Validation Error", "Kelurahan wajib dipilih!", 1500, false);
          return;
        }else if(!this.currentData.batas_hutang_jatuh_tempo || this.currentData.batas_hutang_jatuh_tempo === ''){
          AlertPopup("error", "Validation Error", "Batas waktu hutang wajib diisi!", 1500, false)
          return
        }else if(!this.currentData.syarat_pembayaran || this.currentData.syarat_pembayaran === ''){
          AlertPopup("error", "Validation Error", "Termin wajib dipilih!", 1500, false)
          return
        }else if(!this.currentData.mata_uang || this.currentData.mata_uang === ''){
          AlertPopup("error", "Validation Error", "Mata uang wajib dipilih!", 1500, false)
          return
        }else if(!this.currentData.alamat_pajak || this.currentData.alamat_pajak === '' || this.currentData.alamat_penagihan.length < 5){
          AlertPopup("error", "Validation Error", "Alamat pajak wajib dilengkapi!", 1500, false);
          return;
        }else if(!this.currentData.kode_pos_pajak || String(this.currentData.kode_pos_pajak).length < 5){
          AlertPopup("error", "Validation Error", "Kode Pos pajak masih kosong / kurang dari 5 digit!", 1500, false);
          return;
        }else if(!this.currentData.provinsi_pajak || this.currentData.provinsi_pajak === ''){
          AlertPopup("error", "Validation Error", "Provinsi pajak wajib dipilih!", 1500, false);
          return;
        }else if(!this.currentData.kabupaten_pajak || this.currentData.kabupaten_pajak === ''){
          AlertPopup("error", "Validation Error", "Kabupaten pajak wajib dipilih!", 1500, false);
          return;
        }else if(!this.currentData.kecamatan_pajak || this.currentData.kecamatan_pajak === ''){
          AlertPopup("error", "Validation Error", "Kecamatan pajak wajib dipilih!", 1500, false);
          return;
        }else if(!this.currentData.kelurahan_pajak || this.currentData.kelurahan_pajak === ''){
          AlertPopup("error", "Validation Error", "Kelurahan pajak wajib dipilih!", 1500, false);
          return;
        }else if(this.currentData.daftar_kontak.length > 0) {
          for (let i = 0; i < this.currentData.daftar_kontak.length; i++) {
            const item = this.currentData.daftar_kontak[i];
            if (!item.nama || item.nama === '') {
              AlertPopup("error", "Validation Error", "Nama kontak tidak boleh kosong!", 1500, false);
              return;
            } else if (!item.posisi || item.posisi === '') {
              AlertPopup("error", "Validation Error", "Posisi Jabatan tidak boleh kosong!", 1500, false);
              return;
            } else if (!item.whatsapp || item.whatsapp === '') {
              AlertPopup("error", "Validation Error", "Nomor WhatsApp kontak tidak boleh kosong!", 1500, false);
              return;
            } else if (!item.agama || item.agama === '') {
              AlertPopup("error", "Validation Error", "Agama kontak harus dipilih!", 1500, false);
              return;
            }
          }
        }
      
      // Make "Alamat Lainnya" optional but validate if filled
      if (this.currentData.alamat_lainnya && this.currentData.alamat_lainnya.length > 0) {
        if (this.currentData.alamat_lainnya.length < 5) {
            AlertPopup("error", "Validation Error", "Alamat Lainnya minimal 5 karakter!", 1500, false);
            return;
        }
        if (!this.currentData.kode_pos_lainnya || String(this.currentData.kode_pos_lainnya).length < 5) {
            AlertPopup("error", "Validation Error", "Kode Pos lainnya masih kosong / kurang dari 5 digit!", 1500, false);
            return;
        }
        if (!this.currentData.provinsi_lainnya || this.currentData.provinsi_lainnya === '') {
            AlertPopup("error", "Validation Error", "Provinsi lainnya wajib dipilih jika Alamat Lainnya diisi!", 1500, false);
            return;
        }
        if (!this.currentData.kabupaten_lainnya || this.currentData.kabupaten_lainnya === '') {
            AlertPopup("error", "Validation Error", "Kabupaten lainnya wajib dipilih jika Alamat Lainnya diisi!", 1500, false);
            return;
        }
        if (!this.currentData.kecamatan_lainnya || this.currentData.kecamatan_lainnya === '') {
            AlertPopup("error", "Validation Error", "Kecamatan lainnya wajib dipilih jika Alamat Lainnya diisi!", 1500, false);
            return;
        }
        if (!this.currentData.kelurahan_lainnya || this.currentData.kelurahan_lainnya === '') {
            AlertPopup("error", "Validation Error", "Kelurahan lainnya wajib dipilih jika Alamat Lainnya diisi!", 1500, false);
            return;
        }
      }

      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // CloseLoading()
      // return true


      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'customer_update';
      } else {
        this.api = base_url + 'customer_update';
      }


      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            goPage('customer');
            CloseLoading();
          }
          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },

    // for provinsi dll
    // async provinsi_index() {
    //   ShowLoading();
    //   return new Promise((resolve, reject) => {
    //     axios.post(base_url_wilayah_indonesia + "provinsi_index", '')
    //       .then((response) => {
    //         // console.log(response);
    //         if (response.data.status === true) {
    //           this.provinsiList = response.data.data.map((item) => ({
    //             text: item.nama,
    //             id: item.id,
    //             value: item.nama,
    //           }));

    //           this.provinsiListLainnya = response.data.data.map((item) => ({
    //             text: item.nama,
    //             id: item.id,
    //             value: item.nama,
    //           }));

    //           this.provinsiListPajak = response.data.data.map((item) => ({
    //             text: item.nama,
    //             id: item.id,
    //             value: item.nama,
    //           }));
    //           resolve(response); // Resolve the promise with the response
    //         } else {
    //           reject(new Error("Failed to load provinces")); // Reject the promise
    //         }
    //         CloseLoading();
    //       })
    //       .catch((error) => {
    //         ErrorConnectionTimeOut(error);
    //         reject(error); // Reject the promise with the error
    //       });
    //   });
    // },

    // async kabupaten_index(id, active_page) {

    //   if(active_page == 'alamat') {

    //     this.kabupatenList = [];
    //   } else if(active_page == 'alamat_lainnya') {

    //     this.kabupatenListLainnya = [];
    //   }else if(active_page == 'alamat_pajak') {

    //     this.kabupatenListPajak = [];
    //   }

    //   ShowLoading();
    //   const formData = new FormData();
    //   formData.append("id", id);

    //   try {
    //     const response = await axios.post(base_url_wilayah_indonesia + "kabupaten_index", formData);
    //     if (response.data.status === true) {
    //       if(active_page == 'alamat'){

    //         this.kabupatenList = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }else if(active_page == 'alamat_lainnya'){
    //         this.kabupatenListLainnya = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       } else if(active_page == 'alamat_pajak'){
    //         this.kabupatenListPajak = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }
    //     }
    //     CloseLoading();
    //   } catch (error) {
    //     ErrorConnectionTimeOut(error);
    //     CloseLoading();
    //   }
    // },

    // async kecamatan_index(id, active_page) {

    //   if(active_page == 'alamat'){
        
    //     this.kecamatanList = [];
    //   }else if(active_page == 'alamat_lainnya'){
    //     this.kecamatanListLainnya = [];
    //   } else if(active_page == 'alamat_pajak'){
    //     this.kecamatanListPajak = [];
    //   }

    //   ShowLoading();
    //   const formData = new FormData();
    //   formData.append("id", id);

    //   try {
    //     const response = await axios.post(base_url_wilayah_indonesia + "kecamatan_index", formData);
    //     if (response.data.status === true) {

    //       if(active_page == 'alamat'){

    //         this.kecamatanList = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }else if(active_page == 'alamat_lainnya'){
    //         this.kecamatanListLainnya = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }else if(active_page == 'alamat_pajak'){
    //         this.kecamatanListPajak = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }
    //     }
    //     CloseLoading();
    //   } catch (error) {
    //     ErrorConnectionTimeOut(error);
    //     CloseLoading();
    //   }
    // },

    // async kelurahan_index(id, active_page) {

    //   if(active_page == 'alamat'){

    //     this.kelurahanList = [];
    //   }else if(active_page == 'alamat_lainnya'){
        
    //     this.kelurahanListLainnya = [];
    //   }else if(active_page == 'alamat_pajak'){
        
    //     this.kelurahanListPajak = [];
    //   }

    //   ShowLoading();
    //   const formData = new FormData();
    //   formData.append("id", id);

    //   try {
    //     const response = await axios.post(base_url_wilayah_indonesia + "kelurahan_index", formData);
    //     if (response.data.status === true) {

    //       if(active_page == 'alamat'){

    //         this.kelurahanList = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }else if(active_page == 'alamat_lainnya'){
    //         this.kelurahanListLainnya = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }else if(active_page == 'alamat_pajak'){
    //         this.kelurahanListPajak = response.data.data.map((item) => ({
    //           text: item.nama,
    //           id: item.id,
    //           value: item.nama,
    //         }));
    //       }
    //     }
    //     CloseLoading();
    //   } catch (error) {
    //     ErrorConnectionTimeOut(error);
    //     CloseLoading();
    //   }
    // },

    async provinsi_index() {
      ShowLoading();
      return new Promise((resolve, reject) => {
        axios.post(base_url_wilayah_indonesia + "provinsi_index", '')
          .then((response) => {
            console.log(response);
            if (response.data.status === true) {
              this.provinsiList = response.data.data.map((item) => ({
                text: item.nama,
                id: item.id,
                value: item.nama,
              }));
              resolve(response); // Resolve the promise with the response
            } else {
              reject(new Error("Failed to load provinces")); // Reject the promise
            }
            CloseLoading();
          })
          .catch((error) => {
            ErrorConnectionTimeOut(error);
            reject(error); // Reject the promise with the error
          });
      });
    },

    async kabupaten_index(id) {
      this.kabupatenList = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kabupaten_index", formData);
        if (response.data.status === true) {
          this.kabupatenList = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },
    async kecamatan_index(id) {
      this.kecamatanList = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kecamatan_index", formData);
        if (response.data.status === true) {
          this.kecamatanList = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },

    async kelurahan_index(id) {
      this.kelurahanList = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kelurahan_index", formData);
        if (response.data.status === true) {
          this.kelurahanList = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },


    // FOR WHEN COPY ADDRESS IN ALAMAT LAINNYA THEY CALLED THE INDEX FROM HERE 
    // provinsi for pajak
    async provinsi_index2() {
      ShowLoading();
      return new Promise((resolve, reject) => {
        axios.post(base_url_wilayah_indonesia + "provinsi_index", '')
          .then((response) => {
            console.log(response);
            if (response.data.status === true) {
              this.provinsiList2 = response.data.data.map((item) => ({
                text: item.nama,
                id: item.id,
                value: item.nama,
              }));
              resolve(response); // Resolve the promise with the response
            } else {
              reject(new Error("Failed to load provinces")); // Reject the promise
            }
            CloseLoading();
          })
          .catch((error) => {
            ErrorConnectionTimeOut(error);
            reject(error); // Reject the promise with the error
          });
      });
    },

    async kabupaten_index2(id) {
      this.kabupatenList2 = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kabupaten_index", formData);
        if (response.data.status === true) {
          this.kabupatenList2 = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },
    async kecamatan_index2(id) {
      this.kecamatanList2 = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kecamatan_index", formData);
        if (response.data.status === true) {
          this.kecamatanList2 = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },

    async kelurahan_index2(id) {
      this.kelurahanList2 = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kelurahan_index", formData);
        if (response.data.status === true) {
          this.kelurahanList2 = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },


    // provinsi index for lainnya
    async provinsi_index3() {
      ShowLoading();
      return new Promise((resolve, reject) => {
        axios.post(base_url_wilayah_indonesia + "provinsi_index", '')
          .then((response) => {
            console.log(response);
            if (response.data.status === true) {
              this.provinsiList3 = response.data.data.map((item) => ({
                text: item.nama,
                id: item.id,
                value: item.nama,
              }));
              resolve(response); // Resolve the promise with the response
            } else {
              reject(new Error("Failed to load provinces")); // Reject the promise
            }
            CloseLoading();
          })
          .catch((error) => {
            ErrorConnectionTimeOut(error);
            reject(error); // Reject the promise with the error
          });
      });
    },

    async kabupaten_index3(id) {
      this.kabupatenList3 = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kabupaten_index", formData);
        if (response.data.status === true) {
          this.kabupatenList3 = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },

    async kecamatan_index3(id) {
      this.kecamatanList3 = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kecamatan_index", formData);
        if (response.data.status === true) {
          this.kecamatanList3 = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },

    async kelurahan_index3(id) {
      this.kelurahanList3 = [];
      ShowLoading();
      const formData = new FormData();
      formData.append("id", id);

      try {
        const response = await axios.post(base_url_wilayah_indonesia + "kelurahan_index", formData);
        if (response.data.status === true) {
          this.kelurahanList3 = response.data.data.map((item) => ({
            text: item.nama,
            id: item.id,
            value: item.nama,
          }));
        }
        CloseLoading();
      } catch (error) {
        ErrorConnectionTimeOut(error);
        CloseLoading();
      }
    },

  },

};
</script>
