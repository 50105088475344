<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <layouts></layouts>
      <sidebar-menu></sidebar-menu>
      <div class="page-wrapper">
        <div class="content">
          <pageheader :title="title" :title1="title1" />
  
          <!-- /add -->
          <div class="card">
            <div class="card-body">
              <!-- <Form @submit="update" :validation-schema="schema" v-slot="{ errors }"> -->
              <form @submit.prevent="insert">
  
                <div class="row">
                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center">
                      <label class="me-3 mb-0 " style="width: 150px;">Nama Group Produk <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="currentData.name" placeholder="" />
                    </div>
  
                  </div>
                  <div class="col-lg-12 col-sm-6 col-12 mb-4">
                    <div class="form-group d-flex align-items-center">
                      <label class="me-3 mb-0 " style="width: 150px;">Kode <span class="text-danger">*</span></label>
                      


                      <multiselect v-model="selectedItems" id="ajax" label="text" track-by="value" placeholder="Select Item"
                                open-direction="bottom" :options="needData1" :multiple="true" :searchable="true" :loading="isLoading"
                                :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300"
                                :limit="10" :limit-text="limitText" :max-height="600" :show-no-results="false" :hide-selected="true"
                                @search-change="asyncFind" style="border: none !important">

                        <template #tag="{ option, remove }"><span class="custom__tag"><span>{{ option.text }}</span><span
                          class="custom__remove" @click="remove(option)">❌</span></span></template>

                        <template #clear="props">
                          <div class="multiselect__clear" v-if="selectedItems.length"
                              @mousedown.prevent.stop="clearAll(props.search)"></div>
                        </template>

                        <template #noResult>
                          <span>Oops! No data match or found.</span>
                        </template>

                      </multiselect>

                      <!-- <multi-select :options="filteredData" v-model="searchQuery" :selected-options="items"  placeholder="select item"
                        @select="onSelect" @input="onSearch">
                        
                      </multi-select> -->
                      

                      
                      <!-- <input type="text" class="form-control" v-model="currentData.code" placeholder="" /> -->
                    </div>
  
                  </div>
                  <!-- <div class="col-lg-12 col-sm-12 col-12">
  
                    <label class="mb-">Gambar </label>
                    <div class="custom-file-container" data-upload-id="product-image">
                      <label v-show="isGambar == true" @click="isGambar = false">Remove
                        <a href="javascript:void(0)" class="custom-file-container__image-clear"
                          title="Clear Image">x</a></label>
                      <label class="custom-file-container__custom-file">
                        <input type="file" @change="updateIsGambar()" ref="gambar"
                          class="custom-file-container__custom-file__custom-file-input" accept="image/*" />
                        <input type="hidden" name="MAX_FILE_SIZE" value="10485760" />
                        <span class="custom-file-container__custom-file__custom-file-control"></span>
                      </label>
                      <div class="custom-file-container__image-preview"></div>
                    </div>
  
                  </div> -->
  
                  <!-- <div class="col-lg-12">
                    <button type="submit" class="btn btn-submit me-2">Simpan</button>
                    <router-link to="payment" class="btn btn-cancel">Batal</router-link>
                  </div> -->
  
                  <div class="simpan-button d-flex justify-content-end mt-5">
                      <button type="submit" class="btn btn-primary me-2" style="width: 215px; border-radius: 12px;">Simpan</button>
                      <RouterLink to="group_product" class="btn btn-outline-danger" style="width: 215px; border-radius: 12px; border: 1px solid #D6B884 !important;">Batal</RouterLink>
                  </div>
                </div>
  
              </form>
            </div>
          </div>
          <!-- /add -->
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import Cookies from "js-cookie";
  import axios from "axios";
  import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, goPage, AlertBottom } from "@/assets/js/function";
  import Multiselect from 'vue-multiselect'
  import 'vue-multiselect/dist/vue-multiselect.min.css';
  // import { MultiSelect } from 'vue-search-select';
  import unionWith from 'lodash/unionWith'
  import isEqual from 'lodash/isEqual'
  
  // import { Form, Field } from "vee-validate";
  // import * as Yup from "yup";
  
  export default {
    // components: {
    //   Form,
    //   Field,
    // },
    name: "editkategori",
    data() {
      return {
        filter: true,
        title: "Tambah Group Produk",
        title1: "tambah group produk",
        config: "",
        api: "",
  
        //data
        currentData: {
        },

        needData1: [],

        items: [],
        lastSelectItem: {},
        

        // for exepriment multi select if have thousands data
        isLoading: false,
        selectedItems: [],
        // selectedItems: [],
        // filteredData: [],
        // searchQuery: '',
        

        // currentDate: new Date,
        //gambar
        // gambar_file_64: "",
        // isGambar: false,
      };
    },

    mounted() {
      let cekId = document.getElementById('ajax')
      cekId.style.border='none'
      // console.log(cekId)
    },

    
  
    created() {
      const token = Cookies.get("token_refresh");
      this.config = {
        headers: {
          Authorization: `Bearer ` + token,
        },
        timeout: 30000,
      }

      this.prepareData()
      // this.onSearch = debounce(this.onSearch, 300); // Debounce the search with a 300ms delay
    },
    // setup() {
  
    //   const schema = Yup.object().shape({
    //     name: Yup.string().required("Phone Number is required"),
  
    //   });
  
    //   return {
    //     schema,
  
    //   };
    // },
   
    methods: {

      limitText (count) {
        return `and ${count} other items`
      },
      asyncFind (query) {
        this.isLoading = true
        this.ajaxFindItem(query).then(response => {
          this.needData1 = response
          this.isLoading = false
        })
      },
      
      clearAll () {
        this.selectedItems = []
      },
      
      async ajaxFindItem(query) {
        try {
          // Ensure data is loaded
          this.prepareData();

          // Filter `needData1` based on user input (query)
          const filteredData = this.needData1.filter(item =>
            item.text.toLowerCase().includes(query.toLowerCase())
          );

          return filteredData;
        } catch (error) {
          console.error("Error filtering items:", error);
          return [];
        }
      },

      
  
      // getNameFileUrl(url) {
      //   const parts = url.split('/');
      //   const fileName = parts[parts.length - 1];
      //   return fileName;
      // },
      // convertToBase64(file) {
      //   return new Promise((resolve, reject) => {
      //     const reader = new FileReader();
      //     reader.onload = () => {
      //       resolve(reader.result); // Resolve with the result after loading
      //     };
      //     reader.onerror = reject; // Reject if an error occurs
      //     reader.readAsDataURL(file);
      //   });
      // },
      // updateIsGambar() {
      //   if (this.$refs.gambar.value != "") {
      //     this.isGambar = true;
      //   } else {
      //     this.isGambar = false;
      //   }
  
  
      //   const file = this.$refs.gambar.files[0];
      //   if (file) {
      //     this.convertToBase64(file).then(base64String => {
      //       this.gambar_file_64 = base64String;
      //     });
      //   }
  
      // },

      onSelect(items, lastSelectItem) {
        this.items = items
        this.lastSelectItem = lastSelectItem
      },


      // reset() {
      //   this.items = [] // reset
      // },
      // // select option from parent component
      // selectOption() {
      //   this.items = unionWith(this.items, [this.options[0]], isEqual)
      // },

      

      prepareData() {

        // customer(old) / bidang industri
        const needData1 = JSON.parse(localStorage.getItem('needData1')) || [];
        console.log(needData1)
        this.needData1 = needData1.map((item) => ({
          value: item.id,
          text: `${item.nama} (${item.code})`, // Combine name and code here
          // code: item.code,
        }));


        // needData1.forEach((item, index) => {
        //   const element = { value: item.id, text: item.nama, code: item.code }
        //   console.log(element)
        //   this.needData1.push(element);
        // });

      },
      

      
  
      insert() {
        ShowLoading();
        let formData = new FormData();
  
        Object.entries(this.currentData).forEach(([key, value]) => {
          formData.append(key, value);
        });

        if(!this.currentData.name || this.currentData.name === ''){
          AlertPopup("error", "Validation Error", "Nama Group harus diisi!", 1500, false)
          return;
        }
        // else if(!this.selectedItems || this.selectedItems === ''){

        //   AlertPopup("error", "Validation Error", "Kode Produk Wajib diisi!", 1500, false)
        //   return;
        // }

        let id_kode = []


        // this.items.forEach((item) => {
        //   id_kode.push(item.value);
        // });

        this.selectedItems.forEach((item) => {
          id_kode.push(item.value); // Ensure `value` matches the correct field in `needData1`
        });

        // Validate if id_kode is still empty
        if (id_kode.length === 0) {
            AlertPopup("error", "Validation Error", "Kode Produk Wajib dipilih!", 1500, false);
            return;
        }

        // Append the combined id_customer to formData
        formData.append('id_item', id_kode.join(','));
  
        // formData.delete("gambar");
  
        // if (this.$refs.gambar.value != "") {
  
        //   formData.append("gambar", this.gambar_file_64);
        // }
  
        // for (let [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`);
        // }
        // CloseLoading()
        // return true

        if (process.env.NODE_ENV === "development") {
          this.api = base_url + 'group_product_insert';
        } else {
          this.api = base_url + 'group_product_insert';
        }
  
        axios
          .post(this.api, formData, this.config)
          .then((response) => {
            if (response.data.status == true) {
              AlertBottom(response.data.message);
              goPage("group_product");
              CloseLoading();
            }
            if (response.data.status == false) {
              AlertPopup("error", "", response.data.message, 1500, false);
            }
          })
          .catch((error) => {
            ErrorConnectionTimeOut(error);
          });
      },
  
    },

    components: {

      // MultiSelect,
      Multiselect,

    },
  
  };
  </script>
  
  <style scoped>
  .form-control {
    text-transform: none !important;
  }
  
  label {
    font-weight: 300 !important;
  
  }

  .custom__tag {
    display: inline-flex;
    align-items: center;
    background: #c8c8c8;
    color: white;
    padding: 5px 10px;
    margin: 2px;
    border-radius: 3px;
    font-size: 14px;
  }

  .custom__remove {
    margin-left: 8px;
    cursor: pointer;
    color: #ffdddd;
    font-weight: bold;
  }

  .custom__remove:hover {
    color: #c8c8c8;
  }

  /* .multiselect .multiselect__input{
    border: none !important;
    outline: none !important;
  } */


  /* .multiselect__input {
    border: none !important;
    background: red;
  } */

  
  </style>