<template>
  <!-- Logo -->
  <div class="header-left active">
    <router-link to="index" class="logo logo-normal">
      <!-- <img src="../assets/img/logo.png" alt="" width="10" /> -->
      <div class="">

        <img src="../assets/img/bja-logo-crop.png" alt=""  class="logo-width-responsive"/>
        <!-- style="width: 45%;" -->
      </div>
    </router-link>
    <router-link to="index" class="logo logo-white">
      <img src="../assets/img/logo-white.png" alt="" width="5" />
    </router-link>
    <router-link to="index" class="logo-small">
      <!-- <img src="../assets/img/logo-small.png" class="mb-2" alt="" style="width: 48px!important;" /> -->
      <img src="../assets/img/bja-icon.png" alt="" width="6" />
    </router-link>
    <!-- <a :class="$route.meta.hideClass" id="toggle_btn" href="javascript:void(0);" class="hidder-menu">
      <vue-feather type="chevrons-left" class="feather-16"></vue-feather>
    </a> -->
  </div>
  <!-- /Logo -->
</template>

<style scoped>
/* .header-left {
  background-image: url('../assets/img/nav-bg-left.png');
} */

.hidder-menu {
  transform: translateY(73px);
  border: 1px solid #f4f4f4;
}

.logo-width-responsive {
  width: 45% !important;
}

@media (max-width: 990px) { /* Tablet and smaller screens */
    img.logo-width-responsive {
        width: 15% !important; /* Adjust width for smaller screens */
    }
}
</style>
