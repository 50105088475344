<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <div class="row" v-if="tipe == 'ADMIN'">
                    <!-- header dashboard -->
                    <div class="col-6 gap-2  d-flex align-items-start justify-content-start">
                        <h1 style="font-size: 24px; font-weight: 600;">Dashboard</h1>
                        <!-- <pageheader title="Dashboard" title1="" /> -->

                    </div>
                    <div class="col-6 gap-2  d-flex align-items-start justify-content-end">
                        
                        <!-- <div class="d-flex flex-column justify-content-center align-items-end pt-1 ">
                            <a href="javascript:void(0);" @click="exportExcel()"><img
                                    src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                            <span style="font-size: 10px; margin-top: -8px;">excel</span>
                        </div> -->
                        <div class="">
                            <div class="form-group">   
                                <div class="input-group">
                                    <!-- Left search icon -->
                                    <span class="input-group-text border-end-0" >
                                        <i class="bx bx-search"></i>
                                    </span>
                                    <!-- Search input field -->
                                    <input type="text" class="form-control border-start-0 search-input" placeholder="Search"  />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="">
                            <div class="form-group">
                                
                                <div class="input-groupicon" style="z-index: 100;">
                                    <datepicker v-model="currentData.dfrom" class="picker" :editable="true"
                                        :clearable="false" required />
                                    <a class="addonset">
                                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="">
                            <div class="form-group">
                                
                                <div class="input-groupicon" style="z-index: 100;">
                                    <datepicker v-model="currentData.dto" class="picker" :editable="true"
                                        :clearable="false" required />
                                    <a class="addonset">
                                        <img src="@/assets/img/icons/calendars.svg" alt="img" />
                                    </a>
                                </div>
                            </div>
                        </div> -->
                        <div class="">
                            <button class="btn btn-sm btn-primary custom-btn" @click="index"><i class='bx bx-filter' style="font-size: 20px;"></i></button>
                            <!-- <button class="btn btn-sm btn-primary" @click="index"> <vue-feather
                                    type="filter"></vue-feather></button> -->
                        </div>
                    </div>
                    <!-- upper limit -->
                     <!-- Chart -->
                    <div class="container-fluid">
                        <div class="row">
                            <!-- class="col-lg-8 col-md-12"  -->
                            <div class="col-lg-12 col-md-12" id="leftSideChart">
                                <div class="row">
                                    <div class="col-lg-12 ">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="card" style="border-radius: 12px !important;">
                                                    <div class="card-header">
                                                        <div class="card-title">Sales Target: <span style="color: #007bff;">$23,000</span></div>
                                                    </div>
                                                    <div class="card-body">
                                                        <apexchart
                                                        type="line"
                                                        height="350"
                                                        :options="linechart.morrisbar3"
                                                        :series="linechart.series"
                                                        ></apexchart>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="card " style="height: 455px; border-radius: 12px !important;">
                                                <div class="card-header">
                                                    <div class="card-title">Product Class</div>
                                                </div>
                                                <div class="card-body chart-set">
                                                    <apexchart
                                                    type="pie"
                                                    height="300"
                                                    :options="pieflotChart.flotpie1"
                                                    :series="pieflotChart.series"
                                                    ></apexchart>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <!-- Updates -->
                                            <div class="col-md-6 col-sm-3 col-12 d-flex">
                                                <div class="card w-100" style="border-radius: 12px !important;">
                                                    <div class="card-header d-flex justify-content-between align-items-center">
                                                        <div class="card-title">Updates</div>
                                                        <div class="clear-btn">
                                                            
                                                            <button type="button" class="btn btn-clear"> <img src="../../../assets/img/bell-off.png" alt="bell" class="me-2" style="width: 15px; height: 15px;">Clear All</button>
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        
                                                        <div v-for="(notification, index) in notifications" :key="index" class="mb-3 notification-card">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <div class="notif-container d-flex align-items-center justify-content-between">
                                                                        <div class="d-flex align-items-center">
                                                                            <div class="me-2">
                                                                                <img src="../../../assets/img/approve-icon.png" alt="" style="width: 24px; height: 24px;">
                                                                            </div>
                                                                            <div class="update-notif-content">
                                                                                <p>Order No: <span class="text-primary">{{ notification.orderNo }}</span></p>
                                                                                <p class="text-muted">
                                                                                    Your Order Has been 
                                                                                    <span :class="{ 'text-success': notification.status === 'Approved', 'text-warning': notification.status === 'Rejected'}">
                                                                                        {{ notification.status }}
                                                                                    </span> 
                                                                                    By <span class="text-decoration-underline">{{ notification.approvedBy }}</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <!-- Delete button -->
                                                                        <button type="button" class="btn btn-sm delete-btn" @click="deleteNotification(index)">
                                                                            <i class='bx bxs-trash-alt'></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
        
                                            <!-- Lates Activities -->
                                            <div class="col-lg-6 col-md-3 col-sm-12 d-flex">
                                                <div class="card w-100 h-100" style="border-radius: 12px !important;">
                                                    <div class="card-header">
                                                        <div class="card-title">Latest Activities</div>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-3 miniCardLatesAct">
                                                                <div class="dash-count dash-1  d-flex flex-column justify-content-start align-items-start p-3">
                                                                    <div class="dash-imgs">
                                                                        <!-- <vue-feather type="user" style="width: 24px; height: 24px;"></vue-feather> -->
                                                                        <img src="../../../assets/img/card-icon1.png" alt="card-icon" style="width: 12px; height: 12px;">
                                                                    </div>
                                                                    <div class="dash-counts">
                                                                        <h5 >Order Approval</h5>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 miniCardLatesAct" >
                                                                <div class="dash-count  dash-2 d-flex flex-column justify-content-start align-items-start p-3">
                                                                    <div class="dash-imgs">
                                                                        <!-- <vue-feather type="user" style="width: 12px; height: 12px;"></vue-feather> -->
                                                                        <img src="../../../assets/img/card-icon2.png" alt="card-icon" style="width: 12px; height: 12px;">
                                                                    </div>
                                                                    <div class="dash-counts">
                                                                        <h5>Collection</h5>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 miniCardLatesAct" >
                                                                <div class="dash-count dash-3 d-flex flex-column justify-content-start align-items-start p-3">
                                                                    <div class="dash-imgs">
                                                                        <!-- <vue-feather type="user" style="width: 12px; height: 12px;"></vue-feather> -->
                                                                        <img src="../../../assets/img/card-icon3.png" alt="card-icon" style="width: 12px; height: 12px;">
                                                                    </div>
                                                                    <div class="dash-counts">
                                                                        <h5>Delivery Schedule</h5>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3" >
                                                                <div class="dash-count dash-4 d-flex flex-column justify-content-start align-items-start p-3">
                                                                    <div class="dash-imgs">
                                                                        <!-- <vue-feather type="user" style="width: 12px; height: 12px;"></vue-feather> -->
                                                                        <img src="../../../assets/img/card-icon4.png" alt="card-icon" style="width: 12px; height: 12px;">
                                                                    </div>
                                                                    <div class="dash-counts">
                                                                        <h5>Customer</h5>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>

                                                            
                                                            
                                                        </div>
        
                                                        <div class="row g-2 mt-3">
                                                            <div class="card-header mb-2">
                                                                <div class="card-title">Quick Links</div>
                                                            </div>
                                                            <div class="col-md-4 quick-links ">
                                                                <div class="card ">
                                                                    <div class="card-content py-3 d-flex align-items-center justify-content-center" style="border: none !important; background-color: #FFEDE8;">
                                                                        <img src="../../../assets/img/icon-dash-1.png" alt="mini-icon" style="height: 14px; width: 22px;">
                                                                        <p class="quick-content ml-2">Cash Register</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 quick-links">
                                                                <div class="card ">
                                                                    <div class="card-content py-3 d-flex align-items-center justify-content-center" style="border: none !important; background-color: #FFEDE8;">
                                                                        <img src="../../../assets/img/icon-dash-2.png" alt="mini-icon" style="height: 14px; width: 22px;">
                                                                        <p class="quick-content ml-2">Cheque Register</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 quick-links">
                                                                <div class="card ">
                                                                    <div class="card-content py-3 d-flex align-items-center justify-content-center" style="border: none !important; background-color: #FFEDE8;">
                                                                        <img src="../../../assets/img/icon-dash-3.png" alt="mini-icon" style="height: 14px; width: 22px;">
                                                                        <p class="quick-content ml-2">EFTN Register</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-4 quick-links">
                                                                <div class="card ">
                                                                    <div class="card-content py-3 d-flex align-items-center justify-content-center" style="border: none !important; background-color: #FFEDE8;">
                                                                        <img src="../../../assets/img/icon-dash-4.png" alt="mini-icon" style="height: 18px; width: 18px;">
                                                                        <p class="quick-content ml-2">Challan Register</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
        
        
                                    </div>

                                </div>

                            </div>
                            <div class="col-lg-4 col-md-12  hidden-profile d-none">

                                <div class=" hidden-profile d-none">
                                    <!-- today sales -->
                                    <div class="col-md-12">
                                        <div class="card p-3" style="border-radius: 12px !important;">
                                            <!-- the hidden overflow here -->
                                            <div class="card-header " style="border-bottom: 2px solid rgba(0, 102, 255, 0.1);">
                                                <div class="date-container mb-4">
                                                    <div class="dates-img me-1">
                                                        <img src="../../../assets/img/date_icon.png" alt="date-icon">
                                                    </div>
                                                    <p class="the-date ">
                                                        {{ formattedDate }}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row align-items-center position-relative">
                                                    <div class="col-6 text-center today-sales">
                                                        <p class="mb-1" style="font-weight: 600; color: #707479; font-size: 12px;">Today's Sales</p>
                                                        <h5 style="color: #0066FF; font-weight: 600;">12</h5>
                                                        <div class="vertical-line"></div>
                                                    </div>
                                                    <!-- <hr> -->
                                                    <div class="col-6 text-center today-collection">
                                                        <p class="mb-1" style="font-weight: 600; color: #707479; font-size: 12px;">Today's Collection</p>
                                                        <h5 style="color: #0066FF; font-weight: 600;">19,000</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- current Month -->
                                    <div class="col-md-12">
                                        <div class="card p-3" style="border-radius: 12px !important;">
                                            <!-- the hidden overflow here -->
                                            <div class="card-header " style="border-bottom: 2px solid rgba(0, 102, 255, 0.1);">
                                                <div class="month-container mb-2">
                                                    
                                                    <p class="pt-3" style="font-weight: 500; font-size: 16px; color: #C0C0C0;">
                                                        Current Month
                                                    </p>
                                                    <div class="next-prev-button">
                                                        <!-- next prev button arrow -->
                                                        <button class="arrow-button" id="prev-month">&lt;</button> 
                                                        <button class="arrow-button" id="next-month">&gt;</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row align-items-center position-relative">
                                                    <div class="col-6 text-center today-sales">
                                                        <p class="mb-1" style="font-weight: 600; color: #707479; font-size: 12px;">March 2023 Sales</p>
                                                        <h5 style="color: #0066FF; font-weight: 600;">1200</h5>
                                                        <div class="vertical-line"></div>
                                                    </div>
                                                    <!-- <hr> -->
                                                    <div class="col-6 text-center today-collection">
                                                        <p class="mb-1" style="font-weight: 600; color: #707479; font-size: 12px;">March 2023 Collection</p>
                                                        <h5 style="color: #0066FF; font-weight: 600;">89,700</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- profile  -->
                                    <div class="col-md-12">
                                        <div class="card p-3" style="border-radius: 12px !important;">
                                            <!-- the hidden overflow here -->
                                            <div class="card-header " style="border-bottom: 2px solid rgba(0, 102, 255, 0.1);">
                                                <div class="month-container mb-2">
                                                    
                                                    <p class="pt-3" style="font-weight: 600; font-size: 22px; color: #595E64;">
                                                        Profile Overview
                                                    </p>
                                                    <div class="text-primary" style="font-size: 18px;" >
                                                        <!-- next prev button arrow -->
                                                        <i class='bx bxs-cog'></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="container text-center">
                                                    <div class="justify-content-center">
                                                        <div class="text-center ">
                                                            <img src="../../../assets/img/pic-profil.png" alt="profile-pic" class="img-fluid">
                                                        </div>
                                                        <!-- <hr> -->
                                                        <div class="text-center mb-3">
                                                            <h5 style="color: #0066FF; font-weight: 600;">Eric Liandy</h5>
                                                            <p class="mb-3 text-muted no-uppercase" style="font-weight: 600; font-size: 14px;">Marketing Manager</p>
                                                            <p class="mb-1 text-muted no-uppercase" style="">Phone: 0496 75 675869</p>
                                                            <p class="mb-1 text-muted no-uppercase" style="">Email: business.naoumi@yahoo.com</p>
                                                            
                                                        </div>
                                                        <div class="support-card p-0">
                                                            <div class="card p-3" style="border-radius: 24px; width: 300px; height: 197px; background: linear-gradient(106.07deg, rgba(154, 115, 14, 0.15) 1.91%, rgba(133, 41, 12, 0.15) 96.43%);">
                                                                <div class="row pt-2">
                                                                    <div class="col-6">
                                                                        <div class="img-flag">
                                                                            <img src="../../../assets/img/undraw_flag.png" alt="lady-flag-support">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <p class="no-uppercase subtitle-flag-card text-start">Facing <br> Any Problem</p>
    
                                                                        <div class="support-button d-flex justify-content-start">
                                                                            <button type="button" class="btn btn-support">Expert Support</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    

                    
                    <!-- <div class="col-lg-3 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                           
                        <div class="dash-widgetcontent">
                            <h5>
                                Upcoming Project
                            </h5>
                            <div>
                                <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                    :endAmount="parseInt(resultData.jumlah_project_pending)" :duration="2"
                                    :autoinit="true" />
                            </div>
                            <div class="d-flex align-items-center">
                                <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                    class="text-success me-2"></vue-feather>
                                <div class="text-success">
                                    <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                        :delay="3" :endAmount="parseFloat(resultData.persentasi_project_pending)"
                                        :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                    %
                                </div>

                                <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="dash-widget dash1">
                    
                        <div class="dash-widgetcontent">
                            <h5>
                                Ongoing Project
                            </h5>
                            <div>
                                <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                    :endAmount="parseInt(resultData.jumlah_project_berjalan)" :duration="2"
                                    :autoinit="true" />
                            </div>
                            <div class="d-flex align-items-center">
                                <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                    class="text-success me-2"></vue-feather>
                                <div class="text-success">
                                    <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                        :delay="3" :endAmount="parseFloat(resultData.persentasi_project_berjalan)"
                                        :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                    %
                                </div>

                                <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="dash-widget dash1">
                    
                        <div class="dash-widgetcontent">
                            <h5>
                                Outstanding Project
                            </h5>
                            <div>
                                <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                    :endAmount="parseInt(resultData.jumlah_project_outstanding)" :duration="2"
                                    :autoinit="true" />
                            </div>
                            <div class="d-flex align-items-center">
                                <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                    class="text-success me-2"></vue-feather>
                                <div class="text-success">
                                    <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                        :delay="3" :endAmount="parseFloat(resultData.persentasi_project_outstanding)"
                                        :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                    %
                                </div>

                                <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 col-12">
                    <div class="dash-widget dash1">
                    
                        <div class="dash-widgetcontent">
                            <h5>
                                Finished Project
                            </h5>
                            <div>
                                <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                    :endAmount="parseInt(resultData.jumlah_project_selesai)" :duration="2"
                                    :autoinit="true" />
                            </div>
                            <div class="d-flex align-items-center">
                                <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                    class="text-success me-2"></vue-feather>
                                <div class="text-success">
                                    <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                        :delay="3" :endAmount="parseFloat(resultData.persentasi_project_selesai)"
                                        :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                    %
                                </div>

                                <h6 class="text-secondary ms-2">dari jumlah <br> projek</h6>
                            </div>

                        </div>
                    </div>
                </div>

            <pageheader title="Trip" title1="" />
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                    
                    <div class="dash-widgetcontent">
                        <h5>
                            Upcoming Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_pending)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_pending)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            Ongoing Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_berjalan)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_berjalan)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                  
                    <div class="dash-widgetcontent">
                        <h5>
                            Outstanding Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_outstanding)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_outstanding)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            Finished Trip
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_trip_selesai)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_trip_selesai)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> trip</h6>
                        </div>

                    </div>
                </div>
            </div>
            <pageheader title="Lain-lain" title1="" />
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                    
                    <div class="dash-widgetcontent">
                        <h5>
                            Maintenance / Repairs
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_maintenance)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_maintenance)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                  
                    <div class="dash-widgetcontent">
                        <h5>
                            STNK Expiring
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_asset_stnk_expired)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_asset_stnk_expired)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                   
                    <div class="dash-widgetcontent">
                        <h5>
                            SIM Expiring
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_driver)" :duration="2" :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_sim_expired)" :duration="2"
                                    :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> supir</h6>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
                <div class="dash-widget dash1">
                    
                    <div class="dash-widgetcontent">
                        <h5>
                            Uji KIR Expiring
                        </h5>
                        <div>
                            <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                :endAmount="parseInt(resultData.jumlah_asset_kir_expired)" :duration="2"
                                :autoinit="true" />
                        </div>
                        <div class="d-flex align-items-center">
                            <vue-feather type="upload" data-bs-toggle="tooltip" title="fe fe-upload"
                                class="text-success me-2"></vue-feather>
                            <div class="text-success">
                                <vue3-autocounter class="counter text-success" ref="counter" :startAmount="1385.0"
                                    :delay="3" :endAmount="parseFloat(resultData.persentasi_asset_kir_expired)"
                                    :duration="2" :autoinit="true" decimalSeparator=',' :decimals='1' />
                                %
                            </div>

                            <h6 class="text-secondary ms-2">dari jumlah <br> asset</h6>
                        </div>

                    </div>
                </div>
            </div> -->
                    <!-- lower limit dashboard acs -->
                    <!-- <div class="col-lg-4 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div>
                            <div class="dash-widgetcontent">
                                <h5>
                                    IDR <vue3-autocounter class="counter" ref="counter" :startAmount="1385.0" :delay="3"
                                        :endAmount="parseInt(resultData.total_deposit)" :duration="2"
                                        :autoinit="true" />
                                </h5>
                                <h6>Total Deposit</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="dash-widget dash1">
                            <div class="dash-widgetimg">
                                <span><img src="../../../assets/img/icons/dash2.svg" alt="img" /></span>
                            </div>
                            <div class="dash-widgetcontent">
                                <h5>
                                    IDR <vue3-autocounter class="counter" ref="counter" :startAmount="0" :delay="3"
                                        :endAmount="parseInt(resultData.total_sales_fuqi)" :duration="2"
                                        :autoinit="true" />
                                </h5>
                                <h6>Total Nett Sales</h6>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-lg-6 col-sm-6 col-12 d-flex">
                        <div class="dash-count">
                            <div class="dash-counts">
                                <h4>{{ parseInt(resultData.total_customer) }}</h4>
                                <h5>Customers</h5>
                            </div>
                            <div class="dash-imgs">
                                <vue-feather type="user"></vue-feather>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-12 d-flex">
                        <div class="dash-count das3">
                            <div class="dash-counts">
                                <h4>{{ parseInt(resultData.total_invoices) }}</h4>
                                <h5>Sales Invoice</h5>
                            </div>
                            <div class="dash-imgs">
                                <vue-feather type="file"></vue-feather>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Sales
                                    <span v-if="resultData.year_sales.length != 0">{{
                    resultData.year_sales[0].year }}</span>
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="barchartrender">
                                    <vue3-chart-js class="chartjs-chart" v-if="resultData != undefined"
                                        v-bind="{ ...barchart }"></vue3-chart-js>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title ">Best Seller Product
                                </div>
                            </div>
                            <div class="card-body">
                                <div v-if="barchart2render == true">
                                    <vue3-chart-js class="chartjs-chart" v-bind="{ ...barchart2 }"></vue3-chart-js>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <div class="card-header">
                                <div class="card-title">Pyament Method</div>
                            </div>
                            <div class="card-body">
                                <div v-if="barchart3render == true">
                                    <vue3-chart-js class="chartjs-chart" v-bind="{ ...barchart3 }"></vue3-chart-js>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title">Grafik Jumlah Penjualan Item</h5>
                            </div>
                            <div class="card-body">
                                <apexchart type="bar" height="350" :options="sbarAreaChart.sbar"
                                    :series="sbarAreaChart.series"></apexchart>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h5 class="card-title">Grafik Metode Payment </h5>
                            </div>
                            <div class="card-body">
                                <apexchart type="bar" height="350" :options="sbarAreaChart2.sbar"
                                    :series="sbarAreaChart2.series"></apexchart>
                            </div>
                        </div>
                    </div> -->

                </div>



            </div>
        </div>
    </div>
</template>

<style scoped>
.text-success {
    color: #0DDF7A !important;
}
.text-warning {
    color: #FFA800!important;
}


.card-title {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
}

.card-header {
    padding-bottom: 0px !important;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right {

    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* background-color: red; */
}

.custom-btn {
    border-radius: 12px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
}

.input-group-text {
    border-color: #f4f4f4;
    color: #C3C3C3;
    border-radius: 10px;
    background-color: #fff;
    width: 30px;

}

.search-input {
    border-color: #f4f4f4 !important;
    font-weight: 400 !important;
    text-transform: none !important;
    /* color: #C3C3C3 !important; */
    border-radius: 0 10px 10px 0 !important;
    
}

.search-input::placeholder {
    font-weight: 300 !important;
    color: #c3c3c3;                 
}

.dash-1 {
  background-color: rgba(0, 102, 255, 0.1) !important;
  /* color: #595E64 !important; */
  height: 73px;
  width: 145px;
  
}
.dash-2 {
  background-color: rgba(66, 0, 255, 0.15) !important;
  /* color: #595E64 !important; */
  height: 73px;
  width: 145px;
  
}
.dash-3 {
  background-color: rgba(255, 168, 0, 0.15) !important;
  /* color: #595E64 !important; */
  height: 73px;
  width: 145px;
  
}
.dash-4 {
  background-color: rgba(13, 223, 122, 0.15) !important;
  height: 73px;
  width: 145px;
  
}

.dash-count {
    height: 73px;
    width: 115px;
}

.mini-dash-count {
    height: 23px;
    width: 115px;
}

.dash-counts h5 {
  margin-bottom: 10px !important;
  color: #595E64 !important;
  font-size: 8px;
}




/* .dash-imgs {
  margin-top: auto !important;
} */



.quick-content {
    color: #595E64;
    font-size: 12px;
    font-weight: 600;
    
}

.card-content img {
    margin-right: 10px; 
    width: 30px;             
    height: auto;            
}

.notif-container {
    display: flex;               /* Use Flexbox to align items horizontally */
    align-items: center;         /* Center items vertically */
}

.notif-container img {
    margin-right: 15px;          /* Add some space between the image and the content */
    width: 40px;                 /* Set a fixed width for the image */
    height: auto;                /* Maintain aspect ratio */
}

.update-notif-content p {
    margin: 0;  
    font-size: 12px;                 /* Remove default margin from paragraphs */
}

.btn-clear {
    background-color: #FFEDE8;
    color: #FF3D00;
}
.btn-clear:hover {
    background-color: #FFEDE8;
    color: white;
}

.delete-btn {
    background-color: #FF3D00;
    color: white;
    border-radius: 50%;
}

.notification-card .delete-btn {
    display: none;
}

.notification-card:hover {
    background-color: rgba(0, 102, 255, 0.1) !important;
}

/* Show the delete button on hover */
.notification-card:hover .delete-btn {
    display: inline-block;
}

/* Optional transition for smooth appearance */
.notification-card .delete-btn {
    opacity: 0;
    transform: translateX(10px); /* Slight shift for smooth appearance */
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.notification-card:hover .delete-btn {
    opacity: 1;
    transform: translateX(0);
}

.date-container {
  display: flex;
  align-items: center; /* Vertically center the items */
}

.dates-img img {
  width: 13px; /* Adjust the icon size as needed */
  height: 13px;
  margin-right: 5px; /* Space between the icon and text */
}

.the-date {
  /*color: #AAABAD;*/ /* Text color */
  font-weight: 500;
  font-size: 16px;
  color: #C0C0C0;
}

.vertical-line {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        width: 2px; /* Thickness of the line */
        background-color:rgba(0, 102, 255, 0.1); /* Color of the line */
        transform: translateX(-50%);
}

.month-container {
    display: flex;
    justify-content: space-between; /* Align items to both sides */
    align-items: center; /* Center vertically */
}

.next-prev-button {
    display: flex;
    gap: 10px; /* Space between buttons */
}

.arrow-button {
    background-color: transparent; /* No background */
    border: none; /* No border */
    color: #0066FF; /* Color of the arrows */
    font-size: 20px; /* Size of the arrows */
    cursor: pointer; /* Pointer on hover */
    padding: 5px; /* Padding around the button */
}

.arrow-button:hover {
    color: #004999; /* Change color on hover */
    border-radius: 50%;
    background-color: rgba(0, 102, 255, 0.1);
    width: 40px;
    height: 40px;
}

.no-uppercase {
    text-transform: none !important;
}

.subtitle-flag-card {
    font-family: "abhaya Libre", 'sans-serif';
    font-weight: 400;
    font-size: 20px;
    color: #FF3D00;
}

.btn-support {
    background: #0000000D;
    border-radius: 20px;
    font-family: "abhaya Libre", 'sans-serif';
    font-weight: 400;
    font-size: 14px;
    
}



</style>

<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, openInNewTab } from "@/assets/js/function";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { linechart } from "./data";
import { pieflotChart } from "./data";

export default {
    data() {
        return {


            companyList: [],
            config: "",
            api: "",
            showChart: false,

            //data
            currentData: { dfrom: null, dto: null },
            resultData: {
                dashboard: {
                    total_deposit: 0,
                    total_customer: 0,
                    total_deposit
                        :
                        0,
                    total_invoices
                        :
                        0,
                    total_sales
                        :
                        0,
                    total_sales_fuqi
                        :
                        0,
                    jumlah_project_pending: 0,
                    persentasi_project_pending: 0,
                    jumlah_project_berjalan: 0,
                    persentasi_project_berjalan: 0,
                    jumlah_project_outstanding: 0,
                    persentasi_project_outstanding: 0,
                    jumlah_project_selesai: 0,
                    persentasi_project_selesai: 0,
                    jumlah_trip_pending: 0,
                    persentasi_trip_pending: 0,
                    jumlah_trip_berjalan: 0,
                    persentasi_trip_berjalan: 0,
                    jumlah_trip_outstanding: 0,
                    persentasi_trip_outstanding: 0,
                    jumlah_trip_selesai: 0,
                    persentasi_trip_selesai: 0,
                    jumlah_maintenance: 0,
                    persentasi_maintenance: 0,
                    jumlah_asset_stnk_expired: 0,
                    persentasi_asset_stnk_expired: 0,
                    jumlah_driver: 0,
                    persentasi_sim_expired: 0,
                    jumlah_asset_kir_expired: 0,
                    persentasi_asset_kir_expired: 0,
                },
                year_sales: [
                    {
                        year: "",
                        month: "Mar",
                        sales_amount: "11000000.00"
                    },

                ]
            },
            //token
            token: "",

            barchart: {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            barchart2: {

                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    indexAxis: "y",
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            barchart3: {
                type: 'bar',
                data: {
                    labels: [],
                    datasets: [{
                        // label: 'Sales',
                        data: [],
                        backgroundColor: (ctx) => {
                            const canvas = ctx.chart.ctx;
                            const gradient = canvas.createLinearGradient(0, 0, 0, 250);

                            gradient.addColorStop(0, '#44c4fa');
                            gradient.addColorStop(1, '#664dc9');

                            return gradient;
                        },
                    }]
                },
                options: {
                    indexAxis: "y",
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                    },
                    scales: {
                        yAxes: {
                            ticks: {
                                beginAtZero: true,
                                fontSize: 10,
                                max: 40
                            }
                        },
                        xAxes: {
                            barPercentage: 0.6,
                            ticks: {
                                beginAtZero: true,
                                fontSize: 11
                            }
                        }
                    }
                }
            },
            // sbarAreaChart: {

            //     sbar: {
            //         chart: {
            //             id: "vuechart-example",
            //         },

            //         chart: {
            //             type: 'bar',
            //             height: 350,
            //             stacked: true,
            //             toolbar: {
            //                 show: false,
            //             }
            //         },
            //         plotOptions: {
            //             bar: {
            //                 horizontal: true,
            //             }
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         xaxis: {
            //             categories: [],
            //         }

            //     },

            //     series: [{
            //         name: "Jumlah",
            //         data: []
            //     }],

            // },
            // sbarAreaChart2: {

            //     sbar: {
            //         chart: {
            //             id: "vuechart-example",
            //         },

            //         chart: {
            //             type: 'bar',
            //             height: 350,
            //             stacked: true,
            //             toolbar: {
            //                 show: false,
            //             }
            //         },
            //         plotOptions: {
            //             bar: {
            //                 horizontal: true,
            //             }
            //         },
            //         dataLabels: {
            //             enabled: false
            //         },
            //         xaxis: {
            //             categories: [],
            //         },
            //         yaxis: {
            //             reversed: false,
            //         }

            //     },

            //     series: [{
            //         name: "Total",
            //         colors: ['#FFB55C'],
            //         data: []
            //     }],

            // },

            //tipe user 
            notifications: [
                {
                orderNo: "#89695687498",
                status: "Approved",
                approvedBy: "Mr. Jackod Ds",
                },
                {
                orderNo: "#89695687499",
                status: "Approved",
                approvedBy: "Mr. John Smith",
                }
            ],
            
            tipe: "",
            barchartrender: false,
            barchart2render: false,
            barchart3render: false,
            linechart: linechart,
            pieflotChart: pieflotChart,

            isHiddenProfile: true,

        };
    },
    components: {
        Vue3ChartJs,
    },

    methods: {
        checkProfileVisibility() {
            const profileDiv = this.$refs.profileDiv; // Accessing the ref here
            if (profileDiv) { // Check if profileDiv is not undefined
                this.isHiddenProfile = profileDiv.classList.contains('hidden-profile') && profileDiv.classList.contains('d-none');
            } else {
                console.error('profileDiv is not available');
            }
        },
        
        exportExcel() {
            this.index();

            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                // Create a new Date object
                const date = new Date(value);

                // Use built-in methods to get year, month, and day
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                formData.append(key, formattedDate);
            });

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard_export';
            } else {
                this.api = base_url + 'dashboard_export';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        openInNewTab(response.data.data.file_report);
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        index() {
            ShowLoading();
            // this.barchart2render = false;
            // this.barchart3render = false;
            // this.barchartrender = false;
            let formData = new FormData();

            // Object.entries(this.currentData).forEach(([key, value]) => {
            //     // Create a new Date object
            //     const date = new Date(value);

            //     // Use built-in methods to get year, month, and day
            //     const year = date.getFullYear();
            //     const month = String(date.getMonth() + 1).padStart(2, '0');
            //     const day = String(date.getDate()).padStart(2, '0');
            //     const formattedDate = `${year}-${month}-${day}`;
            //     formData.append(key, formattedDate);
            // });

            // for (let [key, value] of formData.entries()) {

            //     console.log(`${key}: ${value}`);
            // }


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'dashboard';
            } else {
                this.api = base_url + 'dashboard';
            }
            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    console.log(response.data)
                    if (response.data.status == true) {

                        this.resultData = response.data.data;



                        // this.barchartrender = true;
                        // this.barchart.data.labels = [];
                        // this.barchart.data.datasets[0].data = [];

                        // this.barchart2render = true;
                        // this.barchart2.data.labels = [];
                        // this.barchart2.data.datasets[0].data = [];

                        // this.barchart3render = true;
                        // this.barchart3.data.labels = [];
                        // this.barchart3.data.datasets[0].data = [];

                        // response.data.data.year_sales.forEach((item) => {
                        //     this.barchart.data.labels.push(item.month)
                        //     this.barchart.data.datasets[0].data.push(parseInt(item.sales_amount))
                        // });



                        // response.data.data.best_seller.forEach((item) => {
                        //     this.barchart2.data.labels.push(item.item_name)
                        //     this.barchart2.data.datasets[0].data.push(parseInt(item.item_total))
                        // });


                        // response.data.data.payment.forEach((item) => {
                        //     this.barchart3.data.labels.push(item.payment_method)
                        //     this.barchart3.data.datasets[0].data.push(parseInt(item.payment_amount))
                        // });





                        CloseLoading();
                    }



                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },




    },

    mounted() {
        let currentDate = new Date();

        // First day of the month
        let firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

        // Last day of the month
        let lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

        // Assigning to data object
        this.currentData.dfrom = currentDate;
        this.currentData.dto = currentDate;
        // this.index();
        this.tipe = localStorage.getItem('tipe_user');

         //to check if the div profile hidden or no so the div of chart will expand col

    },

    created() {
        this.token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + this.token },
            timeout: 30000,
        }
        
        // this.index();

        // this.dashboard_notif();
        // this.dashboard_index();
        // this.checkProfileVisibility();
        

        
    },

    computed: {
        formattedDate() {
        const today = new Date(); // Get today's date
        const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        const dayName = days[today.getDay()]; // Get the day name
        const day = today.getDate(); // Get the day of the month
        const month = months[today.getMonth()]; // Get the month name
        const year = today.getFullYear(); // Get the full year

        return `${dayName.toUpperCase()}, ${day}/${month.toUpperCase()}/${year}`;
        },

        
    },


};
</script>
