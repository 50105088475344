<template>
  <div class="account-page">
    <!-- Main Wrapper -->
    <div class="main-wrapper">
      <div class="account-content">
        <div class="login-wrapper">

          <div class="login-img">
            <!-- <img src="../../../assets/img/banner_login.png" alt="img" size="10" /> -->
            <!-- <img src="../../../assets/img/banner-login-bja.jpg" alt="img" size="10" class="banner-login" /> -->

          </div>
          <div class="login-content px-6">
            <div class="login-userset">
              <div class="text-center mb-1">
                <img src="../../../assets/img/bja-logo-crop.png" alt="img" class="" width="180" height="160" />
              </div>
              <div class="login-userheading">
                <!-- <h3>Masuk</h3> -->
                 
                <!-- <div class="text-center">
                  <h4 class=" mx-auto sub-logo-text">Silahkan masuk menggunakan <br/> akun anda</h4>
                </div> -->
              </div>
              <Form class="login" @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div class="form-login">
                  <!-- <label>Username</label> -->
                  <div class="form-addons mb-3" style="position: relative;">
                    <div>

                      <Field name="email" type="text" class="form-control custom-input" v-model="email"
                        :class="{ 'is-invalid': errors.email }" placeholder="Username" />
                      <div class="email-icon position-absolute top-50 start-0 translate-middle-y">
                        <i class='bx bx-envelope text-secondary' :class="{ 'bottom-inline': errors.email }"></i>
                      </div>
                    </div>
                    <div class="invalid-feedback" style="display: block;">{{ errors.email }}</div>
                    <div class="emailshow text-danger" id="email"></div>
                    <div class="position-absolute me-3 end-0 top-0 h-100 d-flex align-items-center"
                      v-if="!(errors.email)">
                      <!-- <i class='bx bx-envelope text-secondary' alt="img"></i> -->
                    </div>
                    
                  </div>
                  <!-- <label>Password</label> -->
                  <div class="pass-group">
                    

                    <Field v-if="showPassword" name="password" type="text" class="form-control pass-input custom-input"
                      placeholder="Password" :class="{ 'is-invalid': errors.password }" v-model="password" />
                    <Field v-else name="password" type="password" class="form-control pass-input custom-input" placeholder="Password"
                      :class="{ 'is-invalid': errors.password }" v-model="password" />
                    <div class="password-icon position-absolute top-50 start-0 translate-middle-y">
                      <i class='bx bxs-lock-alt' :class="{ 'bottom-inline': errors.password }"></i>
                    </div>
                    
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                    <div class="position-absolute  end-0 top-0 h-100 d-flex align-items-center"
                      v-if="!(errors.password)">
                      <span @click="toggleShow" class="fas toggle-password" :class="{
                        'fa-eye': showPassword,
                        'fa-eye-slash': !showPassword,
                      }"></span>
                    </div>
                    
                  </div>
                </div>
                <!-- <div class="form-login">
                  <label>Password</label>
                  <div class="pass-group">
                    <Field v-if="showPassword" name="password" type="text" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <Field v-else name="password" type="password" value="123456" class="form-control pass-input"
                      :class="{ 'is-invalid': errors.password }" />
                    <span @click="toggleShow" class="fas toggle-password" :class="{
                      'fa-eye': showPassword,
                      'fa-eye-slash': !showPassword,
                    }"></span>
                    <div class="invalid-feedback">{{ errors.password }}</div>
                    <div class="emailshow text-danger" id="password"></div>
                  </div>
                </div> -->
                <!-- <div class="form-login">
                  <div class="alreadyuser">
                    <h4>
                      <router-link to="forgetpassword" class="hover-a">Forgot Password?</router-link>
                    </h4>
                  </div>
                </div> -->
                <div class="form-login">
                  <button type="submit" class="btn btn-login" style="color: black !important; border-radius: 15px;">Masuk</button>
                  <!-- <router-link class="btn btn-login" to="pin"></router-link> -->
                </div>
              </Form>
              <div class="signinform text-center d-flex justify-content-between">
                <!-- <div>
                  Login as
                  <a href="" @click="setTipeLogin('admin');" class="hover-a" v-if="showAdmin">Admin</a>
                  <a href="" @click="setTipeLogin('owner')" class="hover-a" v-if="showOwner">Owner</a>
                </div> -->
                <div>
                  <a @click="showModal('forgotpassword')" class="hover-a forgot-pass">Forgot Password? </a>
                </div>

                <div>
                  <h4 style="font-size: 12px;">
                    Don’t have an account?
                    <a class="hover-a" style="font-size: 12px;" @click="signUp">Sign up</a>
                    <!-- <a href="www.google.com" class="hover-a">Sign Up</a> -->
                  </h4>
                </div>

              </div>
              <div class="text-center sponsored-img">
                <!-- <p>Powered by </p> -->
                <img src="../../../assets/img/scbd-sponsored.png" alt="Powered by SCBD" class="img-centered">
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="forgotpassword" ref="forgotpassword" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Forgot Password ?</h5>
          <button type="button" class="close" @click="hideModal('forgotpassword')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="changePassword">
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group ">
                  <label>Email / Phone<span class="text-danger">*</span></label>
                  <input type="text" v-model="useremailphone" required />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-end">
            <button type="button" class="btn  btn-secondary" @click="hideModal('forgotpassword')">cancel</button>
            <button type="submit" class="btn  btn-primary">Check Account</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>

<style scoped>
.login-img {
  background-image: url('../../../assets/img/banner-login-bja.jpg');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-size: 800px;
  /* background-repeat: no-repeat; */
  background-position: 0px -500px;
}

.sub-logo-text {
  font-size: 20px;
}

.custom-input {
  border-radius: 15px !important;
  padding: 10px 15px !important;
  padding-left: 45px !important;
  height: 58px !important;
  text-transform: none !important;

}

.custom-input::placeholder {
  text-transform: none !important;
  font-weight: 300 !important;
  color: #c3c3c3 !important;  
  font-size: 14px !important;
}

.forgot-pass {
  color: #000000 !important;
  font-weight: 500;
  font-size: 12px;
}


.email-icon {
  left: 12px; 
  margin-left: 12px;
  pointer-events: none; 
  font-size: 24px;
  color: #D9D9D9;
}

.password-icon {
  left: 12px; 
  margin-left: 12px;
  pointer-events: none; 
  font-size: 24px;
  color: #D9D9D9;
}

.px-6 {
  padding: 0 96px !important;
}

@media (max-width: 768px) { /* Mobile devices */
  .px-6 {
    padding: 0 1rem !important; /* Padding for mobile */
  }
}

@media (min-width: 768px) and (max-width: 1024px) { /* iPads */
  .px-6 {
    padding: 0 1rem !important; /* Padding for iPads */
  }
}

.img-centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.sponsored-img {
  margin-top: 82px;
}

.bottom-inline {
  margin-bottom: 22px;
}
</style>

<script>
// import { ref } from "vue";
// import { useStore } from "vuex";
import { Form, Field } from "vee-validate";
// import { router } from "../../../router";
// import VueRouter from "vue-router";
import * as Yup from "yup";
import axios from "axios";
import Cookies from "js-cookie";

import { goPage, ShowLoading, CloseLoading, base_url, ErrorConnectionTimeOut, AlertPopup } from "@/assets/js/function";


export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      email: "",
      useremailphone: "",
      showAdmin: true,
      showOwner: false,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    setTipeLogin(status) {
      ShowLoading();
      localStorage.setItem('tipe_login', status); goPage('');
    },
    showModal(modal_name) {
      this.$refs[modal_name].classList.add('show');
      this.$refs[modal_name].style.display = 'block';
    },
    hideModal(modal_name) {
      this.$refs[modal_name].classList.remove('show');
      this.$refs[modal_name].style.display = 'none';

    },

    signUp() {

      window.open('https://arthaskyline.com/contact.html', "_blank");
      CloseLoading();

    },

    changePassword() {
      ShowLoading();
      let formData = new FormData();
      formData.append("phone", this.useremailphone);

      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'send_otp';
      } else {
        api = base_url + 'send_otp';
      }
      axios
        .post(api, formData)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status == true) {
            Cookies.set("token", response.data.token);
            Cookies.set("useremailphone", this.useremailphone);
            // Cookies.set("user_type", response.data.data_users.tipe);
            goPage('confirmotp');
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });

    }
  },
  created() {
    // if (localStorage.getItem('tipe_login') == undefined ||
    //   localStorage.getItem('tipe_login') == 'owner' ||
    //   localStorage.getItem('tipe_login') == null) {
    //   this.showAdmin = true;
    //   this.showOwner = false;
    // } else {
    //   this.showAdmin = false;
    //   this.showOwner = true;
    // }

    CloseLoading();
  },
  setup() {
    // let users = localStorage.getItem("storedData");
    // if (users === null) {
    //   let password = [
    //     {
    //       phone: "081234567890",
    //     },
    //   ];
    //   const jsonData = JSON.stringify(password);
    //   localStorage.setItem("storedData", jsonData);
    // }
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required"),
      // .email("Email is invalid"),
      password: Yup.string().nullable().required("Password is required"),
    });
    const onSubmit = (values) => {

      ShowLoading();
      let formData = new FormData();
      formData.append("username", values.email);
      formData.append("password", values.password);
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }

      formData.append("token_firebase", localStorage.getItem('token_firebase'));
      let api = "";
      if (process.env.NODE_ENV === "development") {
        api = base_url + 'login';
      } else {
        api = base_url + 'login';
      }
      axios
        .post(api, formData)
        .then((response) => {
          if (response.data.status == true) {
            Cookies.set("token_refresh", response.data.token_refresh);
            localStorage.setItem('tipe_user', response.data.data_users.tipe);
            localStorage.setItem('auth_menu', JSON.stringify(response.data.data_menu));
            if (response.data.data_users.phone == "") {
              goPage('profile');
              CloseLoading();
              return true;
            }

            if (response.data.data_users.email == "") {
              goPage('profile');
              CloseLoading();
              return true;
            }


            if (response.data.data_users.tipe == 'KAS') {
              goPage('pos');
              CloseLoading();
            } else {

              goPage('index');
              CloseLoading();
            }



          }


          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });


    };
    return {
      schema,
      onSubmit,
    };
  },
};
</script>
